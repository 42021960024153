import React, { useState } from 'react'
import { connect } from 'react-redux'
/// React router dom
import {Switch, Route, Redirect } from 'react-router-dom'
/// Css
import './index.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'   // theme
import 'primereact/resources/primereact.css'                                 // icons
import 'primeflex/primeflex.css'

/// Dashboard
//import Home from "./components/Dashboard/Home";
import PatientDetails from "./components/Patient/PatientDetails";
import PatientIdentify from "./components/Patient/Identify";
import PreIdentify from './components/Patient/PreIdentify';
import NumAssuIdentify from './components/Patient/NumAssuIdentify';
import ProviderView from './components/Providers/ProviderView/ProviderView';
import ConsultationRequest from './components/Consultations/ConsultationForms/ConsultationRequest';
import ConsultationPane from './components/Consultations/ConsultationForms/ConsultationPane';
import ConsultationList from './components/Consultations/ConsultationList/ConsultationList';
import DetailsConsultation from './components/Consultations/ConsultationViews/DetailsConsultation';
import BonSearchForm from './components/Pharmacies/BonSearchForm';
import LaboBonSearchForm from './components/Laboratoires/LaboBonSearchForm';
import ActeSpecBonSearchForm from './components/ActesSpecialises/ActeSpecBonSearchForm';
import DossierMedialPane from './components/DossierMedical/DossierMedialPane';
import AppointmentList from './components/Appointments/AppointmentsList/AppointmentList';
import AppointmentRequest from './components/Appointments/AppointmentRequest';
import AppointmentView from './components/Appointments/AppointmentView/AppointmentView';
import Enroll from './components/Patient/Enroll';
import ClientSearch from './components/Patient/ClientSearch';
import FacturationDetails from './components/Facturation/FacturationDetails';
import FacturationList from './components/Facturation/FacturationList';
import OpticBonSearchForm from './components/Opticiens/OpticBonSearchForm';
import KineBonSearchForm from './components/Kines/KineBonSearchForm'
import PreEnroll from './components/Patient/PreEnroll';
import PartialEnroll from './components/Patient/PartialEnroll';
import Seances from './components/Kines/Seances';
import Dashboard from './components/Dashboard/Dashboard';
import AssuresSearch from './components/Patient/AssuresSearch';
/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error403 from './pages/Error403'
import Profile from './pages/Profile'
import Help from './pages/Help'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

//current user
import { currentUser } from '../store/selectors/CurrentUserSelector';
import { useSelector } from 'react-redux';
//import { currentUser } from '../store/selectors/CurrentUserSelector';

 function BlobSasUrl1(){
  const dt = useSelector(currentUser).sasurl
  return dt
}

const Markup = (props) => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: PreEnroll },
    //{ url: "dashboard", component: PreEnroll },
	  { url: "patient-details", component: PatientDetails },
    { url: "patient-identify", component: PatientIdentify },
    { url: "patient-preidentify", component: PreEnroll },
    { url: "patient-enroll", component: PartialEnroll },
    { url: "identification", component: PreIdentify },
    { url: "identification-assure", component: NumAssuIdentify },
    { url: "dashboard", component: Dashboard },
    { url: 'assure-search', component: AssuresSearch },
    /// Prestataires
    { url: 'provider-view/:id', component: ProviderView },
    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'help-center', component: Help },
    { url: 'profile', component: Profile },
    /// Consultations
    { url: 'consultation-request/:patientId/:patientName', component: ConsultationRequest },
    { url: 'consultation/:id/:clientId/:clientName', component: ConsultationPane },
    { url: 'consultation-list', component: ConsultationList },
    { url: 'consultation-details/:id', component: DetailsConsultation },
    //Pharmacie
    { url: 'pharmacie/service', component: BonSearchForm },
    //Laboratoire
    { url: 'laboratoire/service', component: LaboBonSearchForm },
    //Actes Spécialisés
    { url: 'actes-specialises/service', component: ActeSpecBonSearchForm },
    //Optique
    { url: 'optique/service', component: OpticBonSearchForm },
    //Optique
    { url: 'kine/service', component: KineBonSearchForm },
    { url: 'kine/seances', component: Seances },
    //Dossier Médical
    { url: 'dossier-medical/:id', component: DossierMedialPane },
    //Rendez-vous
    { url: 'appointments-list', component: AppointmentList },
    { url: 'appointments', component: AppointmentView },
    { url: 'appointment-new/:id', component: AppointmentRequest },
    { url: 'client-register/:id', component: Enroll },
    { url: 'client-search', component: ClientSearch },
    //Facturation
    { url: 'facturation', component: FacturationList },
    { url: 'facturation-details/:id', component: FacturationDetails },
    //Error pages
    { url: 'access-denied', component: Error403 }

  ]


  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      //element={ props.currentUser && props.currentUser.isFirstLogin ? <Redirect replace to="/profile#profile-settings" /> : <data.component /> }
                      //component={data.component}
                    >
                      { props.currentUser && props.currentUser.isFirstLogin && !data.url.includes('profile') && !data.url.includes('help') ? 
                        <Redirect to="/profile#profile-settings" /> : 
                        <data.component /> 
                      }
                    </Route>
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}
const mapStateToProps = (state) => {
    return {
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(Markup);
