import React, { Suspense, useEffect, useState } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import LogoutLink from './Logout';

import profile from "../../../images/avatar-default.jpg";

import { currentUser } from "../../../store/selectors/CurrentUserSelector";

//firebase
import { db } from "../../../Utils/firebase";
import { onValue, ref } from "firebase/database";

import "./Header.css";
import { withTranslation } from "react-i18next";
import Lang from "./lang";

const Header = ({ onNote, toggle, onProfile, onNotification, onBox, currentUser, t }) => {
   const [notifications, setNotifications] = useState(0);

   var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName = filterName;

   useEffect(() => {
      const query = ref(db, "Notifications/Provider");
      return onValue(query, (snapshot) => {
         const data = snapshot.val();
         if (data) {
            setNotifications(Object.keys(data).length);
         }
      });
   }, []);
   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize", color: "white" }}
                     >
                        {/* {finalName.join(" ")} */}
                     </div>
                  </div>
                  <ul className="navbar-nav header-right">
                     <li className="nav-item dropdown notification_dropdown">
                        <Link
                           className="nav-link  ai-icon text-white border-white"
                           to="#"
                           role="button"
                           data-toggle="dropdown"
                           onClick={() => onNotification()}
                        >
                           <i className="la la-bell"></i>
                           <span className={notifications > 0 ? "badge light text-white bg-danger blink" : "badge light text-white bg-danger"}>
                              {notifications}
                           </span>
                        </Link>
                        <div
                           className={`dropdown-menu dropdown-menu-right ${toggle === "notification" ? "show" : ""
                              }`}
                        >
                           <PerfectScrollbar
                              id="DZ_W_Notification1"
                              className={` widget-media dz-scroll p-3 height380 ${toggle === "notification"
                                    ? "ps ps--active-y"
                                    : ""
                                 }`}
                           >
                              <ul className="timeline">
                                 {/* <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2">
                                          <img
                                             alt=""
                                             width={50}
                                             src={avatar}
                                          />
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Dr sultads Send you Photo
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2 media-info">
                                          KG
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Resport created successfully
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2 media-success">
                                          <i className="fa fa-home" />
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Reminder : Treatment Time!
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2">
                                          <img
                                             alt=""
                                             width={50}
                                             src={avatar}
                                          />
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Dr sultads Send you Photo
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2 media-danger">
                                          KG
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Resport created successfully
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="timeline-panel">
                                       <div className="media mr-2 media-primary">
                                          <i className="fa fa-home" />
                                       </div>
                                       <div className="media-body">
                                          <h6 className="mb-1">
                                             Reminder : Treatment Time!
                                          </h6>
                                          <small className="d-block">
                                             29 July 2020 - 02:26 PM
                                          </small>
                                       </div>
                                    </div>
                                 </li> */}
                              </ul>
                           </PerfectScrollbar>
                           <Link className="all-notification" to="#">
                              See all notifications{" "}
                              <i className="ti-arrow-right" />
                           </Link>
                        </div>
                     </li>
                     <li
                        className="nav-item dropdown header-profile"
                        onClick={() => onProfile()}
                     >
                        <Link
                           className="nav-link"
                           to="#"
                           role="button"
                           data-toggle="dropdown"
                        >
                           <img src={profile} width={20} alt="" />
                           <div className="header-info">
                              <span>
                                 {t('common.bonjour')}, <strong>{currentUser?.username || "Guest"}</strong>
                              </span>
                           </div>
                        </Link>

                        <div
                           className={`dropdown-menu dropdown-menu-right ${toggle === "profile" ? "show" : ""
                              }`}
                        >
                           <Link
                              to="/profile"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                 <circle cx={12} cy={7} r={4} />
                              </svg>
                              <span className="ml-2">Profile </span>
                           </Link>
                           <Link
                              to="./email-inbox"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                 <polyline points="22,6 12,13 2,6" />
                              </svg>
                              <span className="ml-2">Inbox </span>
                           </Link>
                           <Link
                              to="/help-center"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width={18}
                                 height={18}
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth={2}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                 <polyline points="22,6 12,13 2,6" />
                              </svg>
                              <span className="ml-2">{t('layout.help-center')} </span>
                           </Link>

                           <LogoutLink />
                        </div>
                     </li>
                     <li className="nav-item">
                        <Lang />
                     </li>
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};
const mapStateToProps = (state) => {
   return {
      currentUser: currentUser(state),
   };
};

export default withTranslation()(connect(mapStateToProps)(Header));
