import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getAppointmentsAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

export const AppointmentList = (props) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            doctorName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            motifRdv: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateRdv: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder= {t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'doctorName', headerName: t('common.doctor'), filterPlaceholder: t('appointment.filter-doctor'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'motifRdv', headerName: t('appointment.reason'), filterPlaceholder: t('appointment.filter-motif'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'dateRdv', filterField: 'dateRdv', dataType: 'date', headerName: t('appointment.appointment-date'), filterPlaceholder: t('appointment.filter-dateRdv'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: dateRdvBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: t('appointment.appointment-status'), filterPlaceholder: t('appointment.filter-status'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <>
            {rowData.status === STATUSES.pending && <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                <button className="btn btn-primary shadow btn-xs sharp mr-2"
                    onClick={(e)=>updateAppointment(rowData.id)}
                >
                    <i className="fa fa-pencil"></i>
                </button>
            </div>}
        </>
    };

    const updateAppointment = (id) => {

        props.history.push('/appointment-new/'+id);
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.appointments.map(appointment => {
            return appointment.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} showTime hourFormat="24" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy hh:mm" mask="99/99/9999 00:00" />;
    };

    const dateRdvBodyTemplate = (rowData) => {
        return formatDate(rowData.dateRdv);
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'Confirmed':
                return 'success';

            case 'Rejected':
                return 'danger';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minutes: '2-digit'
        });
    };

    
    useEffect(() => {
        dispatch(startLoader());
        dispatch(getAppointmentsAction(props.currentUser.providerId, 'secretary'));
    }, []); 


    return (
        <>
            <DataTableBase 
                data={props.appointments.map(appointment => {
                    appointment.dateRdv = new Date(appointment.dateRdv);
                    return appointment;
                })}
                emptyMessage={t('appointment.no-appointments')} 
                filters={filters}
                globalFiltersFields={['doctorName', 'dateRdv', 'motifRdv', 'status']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
            />
            
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        appointments: state.consultation.appointments,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(AppointmentList));