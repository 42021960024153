import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useState, useEffect} from "react";
// import { useTranslation } from "react-i18next";
// import swal from "sweetalert";
// import {
//     loadingToggleAction, identificationAction,
// } from '../../../store/actions/PatientAction';
// import { Link } from "react-router-dom";
import { Col, Card, Button, Spinner, Badge } from 'react-bootstrap'
import { getBonByCodeAction, markActeAsDoneAction, startLoader } from '../../../store/actions/ActesSpecialisesAction';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { getFactureAction } from '../../../store/actions/ProviderActions';
import { calculationRembBaseAction } from '../../../store/actions/FacturationAction';
import { DataTableBase } from '../DataTables/DataTableBase';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import swal from 'sweetalert';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { Card as PRCard } from 'primereact/card';
import { Dialog } from 'primereact/dialog';

const ActeSpecBonSearchForm = (props) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [actes, setActes] = useState([]);
    const [acteIds, setActesIds] = useState([]);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [examArr, setExamArr] = useState([]);
    const [multiBonVisible, setMultiBonVisible] = useState(false);
    const [focusBon, setFocusBon] = useState({});
    const { bon, currentUser, facture, errorMessage, successMessage, showLoading, rembCalculDatas } = props;

    
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'acteName', headerName: t('special-acts.care'), filterPlaceholder: t('special-acts.filter-text'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: acteNameBodyTemplate},
            {name: 'prix', dataType: 'numeric', headerName: t('special-acts.price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'tm', dataType: 'numeric', headerName: t('special-acts.cover'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'prixPatient', dataType: 'numeric', headerName: t('special-acts.patient-price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},            
            {name: 'status', headerName: t('common.status'), filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate}
        ]
    };

    const acteNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.acteName}</span><br/>
            {rowData.message && 
                <>
                    <small className='text-danger'>
                        {rowData.message}
                    </small>
                </>
            }
        </>
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={focusBon?.specialiseBonDetails.map(facture => {
            return facture.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    
    const footerContent = (
        <div>
            <PButton label="Fermer" icon="pi pi-times" onClick={() => setMultiBonVisible(false)} className="p-button-text" />
        </div>
    );

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.exectuted:
                return 'success';

            case STATUSES.rejeted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const isExamSelectable = (rowData) => {
        return rowData.status === STATUSES.pending || rowData.status === STATUSES.accepted
    }

    const loadSelectedExams = (selecExams) => {
        setExamArr(examArr.map(exam => {
            exam.selected = selecExams.includes(exam);
            return exam;
        }));

        if(selecExams.length > 0) {
            let data = {
                providerId: currentUser.providerId,
                patientId: focusBon.prescription.consultation.beneficiaryId,
                acteId: selecExams[0].acteId
            };
            dispatch(calculationRembBaseAction(data, examArr.indexOf(selecExams[0])));
        }
    }

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            dispatch(getBonByCodeAction(search));
        }
    }

    /*const markActeDone = (detail, patientId, acteIndex) => {
        setActes([...actes, detail]);

        setActesIds([...acteIds, detail.acteId]);

        let data = {
            providerId: currentUser.providerId,
            patientId,
            acteId: detail.acteId
        };

        dispatch(calculationRembBaseAction(data, acteIndex));
    }

    const markActeUndone = (detail, patientId) => {
        setActes(actes.filter(acte => {return acte.id !== detail.id}));
        
        setActesIds(acteIds.filter(act => { return act.id !== detail.acteId}));
    }*/

    const loadExecutedActs = () => {
        let data = {};
        data.acteDetails = examArr.filter(elt => elt.selected).map(acte => {
            return {acteSpecialiseBonId: acte.id}
        });
        data.executedBy = currentUser.providerId;
        data.executedName = currentUser.provider.providerName;
        dispatch(startLoader());
        dispatch(markActeAsDoneAction(data));
    }

    const chooseBon = (choosenBon) => {
        setFocusBon(choosenBon);
        setExamArr(choosenBon.specialiseBonDetails ? choosenBon.specialiseBonDetails.map(exam => {
            exam.prix = null;
            exam.prixPatient = null;
            exam.tm = null;
            exam.selected = false;
            exam.message = null;
            return exam;
        }) : []);

        setMultiBonVisible(false);
    }

    const formatDate = (value) => {
        return value ? value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }) : '';
    };

    useEffect(() => {
        if(Array.isArray(bon)){
            if(bon.length > 0) {
                setMultiBonVisible(true);
            }
        }else{
            setFocusBon(bon);
            setExamArr(bon.specialiseBonDetails ? bon.specialiseBonDetails.map(exam => {
                exam.prix = null;
                exam.prixPatient = null;
                exam.tm = null;
                exam.selected = false;
                exam.message = null;
                return exam;
            }) : []);
        }
    }, [bon]);

    useEffect(() => {
        setExamArr(examArr.map((exam, i) => {
            exam.prix = !rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPolice ?
            rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantConvention :
            Math.min(rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPolice);
            exam.prixPatient = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPaye;
            exam.tm = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.tm + '%';
            exam.message = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.message;
            
            return exam;
        }));
    }, [rembCalculDatas]);

    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }, [successMessage, errorMessage]);

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-center mt-4'>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder={t('common.bon-search-placeholder')} required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' data-testid="bon-search" className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                {Array.isArray(bon) && <Dialog header="Choisir le bon à traiter" visible={multiBonVisible} maximizable style={{ width: '50vw'}} onHide={() => setMultiBonVisible(false)} footer={footerContent}>
                    <div className='row'>
                        {bon.map(elt => {
                                return <div className="card col-4" onClick={() => chooseBon(elt)}>
                                        <PRCard title={"Bon n°"+elt.code}>
                                            <ul className="m-0">
                                                <li><b>Prescripteur:</b> {elt.providerName}</li>
                                                <li><b>Date de prescription:</b> {formatDate(new Date(elt.createdAt))}</li>
                                                <li><b>Total examens:</b> {elt.specialiseBonDetails.length}</li>
                                            </ul>
                                        </PRCard>
                                    </div>
                            })}
                    </div>
                </Dialog>}
                <div className='row mt-5 justify-content-center align-items-center h500'>
                    {Object.keys(bon).length === 0 && showLoading && <small>{t('common.await-act-text')}</small>}
                    {Object.keys(bon).length === 0 && !showLoading && <small>{t('common.no-prescription')}</small>}
                    {Object.keys(focusBon).length > 0 &&
                        <div className='col'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('common.patient')}:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.prescription?.consultation?.beneficiaryName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('common.prescriptor')}:</span>
                                <span className='text-bold text-primary ml-2'>{focusBon.prescription?.consultation?.doctorName}</span>
                            </h4>
                            <div className="border-top my-2"></div>
                            <div className='row justify-content-between my-5 mx-2'>
                                <span>{t('common.total-amount')}: <b className='text-primary'>{
                                    examArr.reduce((sum, exam) => {
                                        return exam.selected ? sum + exam.prix : 0
                                    }, 0)
                                }</b></span>
                                <span>{t('common.total-patient-amount')}: <b className='text-primary'>{
                                    examArr.reduce((sum, exam) => {
                                        return exam.selected ? sum + exam.prixPatient : 0
                                    }, 0)
                                }</b></span>
                            </div>
                            <DataTableBase 
                                data={examArr}
                                size="small"
                                tableStyle={{ minWidth: '50rem', width: '100%' }}
                                emptyMessage={t('special-acts.no-act')} 
                                filters={filters}
                                globalFiltersFields={['acteName', 'status']}
                                header={renderHeader()}
                                columns={setColumns()}
                                rows={10} 
                                loading={props.showLoading}
                                rowClick={true}
                                selectedData={examArr.filter(exam => exam.selected)}
                                setSelectedDatas={(val) => loadSelectedExams(val)}
                                isRowSelectable={val => isExamSelectable(val)}
                            />
                            {/*<div className="row">
                                {
                                    bon.specialiseBonDetails?.map((detail, i) => {
                                        return <Col xl='4'>
                                        <Card className='text-white bg-info'>
                                        <Card.Header>
                                            <Card.Title className='text-white'>Code Acte: {detail.acteId}</Card.Title>
                                        </Card.Header>
                                        <Card.Body className=' mb-0'>
                                            <Card.Text>
                                            {detail.acteName}
                                            </Card.Text>
                                            {detail.dentsTraiters && detail.dentsTraiters.length > 0 &&
                                            <> 
                                                <b>Dents:</b>
                                                <div className='row pl-3 my-2'>
                                                    {detail.dentsTraiters?.map((dent, j) => {
                                                        return <Badge variant="warning mr-1 mb-1" key={j}>
                                                            <span>{dent.dentId}</span>
                                                        </Badge>
                                                    })}
                                                </div>
                                            </>}
                                            {(detail.status === STATUSES.pending && !actes.includes(detail)) && 
                                            <div className='row'>
                                                <Button
                                                as='a'
                                                variant='info light'
                                                href='#'
                                                className='btn-card mt-3 mr-3'
                                                onClick={() => markActeDone(detail, bon.prescription.consultation.beneficiaryId, i)}
                                                >
                                                Effectuer
                                                </Button>
                                            </div>
                                            }
                                            {actes.includes(detail) && 
                                            <>
                                                <Badge variant="dark light my-2">
                                                    <i className='fa fa-check mr-2'></i>
                                                    <span>Marqué comme effectué</span>
                                                </Badge><br/>
                                                <Button
                                                as='a'
                                                variant='info light'
                                                href='#'
                                                className='btn-card'
                                                onClick={() => markActeUndone(detail, bon.prescription.consultation?.beneficiaryId)}
                                                >
                                                Retirer
                                                </Button>
                                                <div>
                                                    <h5>Coûts</h5>
                                                    <h6 className='text-warning'>{rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.message}</h6><br/>
                                                    <span>Prix de l'acte: {Math.min(rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPolice)}</span><br/>
                                                    <span>Ticket modérateur: {100 - rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.tm} %</span><br/>
                                                    <span>Montant à payer par le patient: {rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPaye}</span><br/>
                                                </div>
                                            </>}
                                        </Card.Body>
                                        <Card.Footer className=' bg-transparent border-0 text-white'>
                                            {t('statuses.'+detail.status)}
                                        </Card.Footer>
                                        </Card>
                                    </Col>
                                    })
                                }
                            </div>*/}
                            <div className='row justify-content-end'>
                                { showLoading && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !showLoading &&
                                    <Button
                                        as='a'
                                        variant='primary light'
                                        href='#'
                                        className='btn-card'
                                        disabled={!examArr.find(elt => elt.selected)}
                                        onClick={() => {
                                            if(examArr.reduce((sum, exam) => {
                                                return exam.selected ? sum + exam.prixPatient : 0
                                            }, 0) > 0) {
                                                swal({
                                                    title: t('common.paiement-confirm'),
                                                    text:t('common.patient-confirm')+
                                                        bon.prescription.consultation.beneficiaryName
                                                        +t('common.has-paid')+
                                                        examArr.reduce((sum, exam) => {
                                                            return exam.selected ? sum + exam.prixPatient : 0
                                                        }, 0)
                                                        +t('common.due-text'),
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                    }).then((hasPaid) => {
                                                        if (hasPaid) {
                                                            loadExecutedActs();
                                                        }
                                                })
                                            }else{
                                                loadExecutedActs();
                                            }
                                        }}
                                        >
                                        {t('common.validate')}
                                    </Button> }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       bon: state.actesSpecialise.bon,
       successMessage: state.actesSpecialise.successMessage,
       errorMessage: state.actesSpecialise.errorMessage,
       showLoading: state.actesSpecialise.showLoading,
       facture: state.provider.facture,
       rembCalculDatas: state.facturation.rembCalculDatas,
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(ActeSpecBonSearchForm);