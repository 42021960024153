import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TextInput from 'react-autocomplete-input';
import { searchActesSpecAction, searchActesDepKineAction } from '../../../../../store/actions/ProviderActions';
import 'react-autocomplete-input/dist/bundle.css';
import './ConsultationStyle.css';
import { Badge, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const KineForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const frequences = [
        {label: t('kine.day'), value: t('kine.day')},
        {label: t('kine.week'), value: t('kine.week')},
        {label: t('kine.month'), value: t('kine.month')},
    ];
    const typesTraitement = [
        {label: t('kine.new-treatment'), value: t('kine.new-treatment')},
        {label: t('kine.treatment-report'), value: t('kine.treatment-report')},
        {label: t('kine.prolong'), value: t('kine.prolong')},
    ];
    const consult = JSON.parse(localStorage.getItem("consultation"));
    const [withKine, setWithKine] = useState(consult && consult.kine);
    const [typeOrdonance, setTypeOrdonance] = useState(consult && consult.kine && consult.kine.typeOrdonance ? consult.kine.typeOrdonance : '');
    const [bonDetails, setBonDetails] = useState((consult && consult.kine && consult.kine.bonDetails) ? consult.kine.bonDetails : [{acteName: '', acteCode: '', acteId: null, description: ''}]);
    const [isDomicile, setIsDomicile] = useState(consult && consult.kine && consult.kine.isDomicile);
    const [nombreSession, setNombreSession] = useState(consult && consult.kine && consult.kine.nombreSession ? consult.kine.nombreSession : 0);
    const [frequence, setFrequence] = useState(consult && consult.kine && consult.kine.frequence ? consult.kine.frequence : 0);
    const [uniteFrequence, setUniteFrequence] = useState(consult && consult.kine && consult.kine.uniteFrequence ? consult.kine.uniteFrequence : 'jour');
    const [descriptions, setDescriptions] = useState((consult && consult.kine && consult.kine.bonDetails) ? consult.kine.bonDetails.map(elt => elt.description) : ['']);
    const [choosenActes, setChoosenActes] = useState((consult && consult.kine && consult.kine.bonDetails) ? consult.kine.bonDetails.map(elt => { return {acteName: elt.acteName, acteCode: elt.acteCode, acteId: elt.acteId} }) : [{acteName: '', acteCode: '', acteId: null}]);

    const saveDiagnosticUpdates = (data, key) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.kine[key] = data;
        if(key === 'isDomicile') {
            setBonDetails(data ? [...bonDetails, {
                acteName: props.depKine[0].nameFr, 
                acteCode: props.depKine[0].code, 
                acteId: props.depKine[0].id,
                description: ""
            }] : bonDetails.filter(elt => elt.acteId !== props.depKine[0].id));
            consultation.kine["bonDetails"] = data ? [...bonDetails, {
                acteName: props.depKine[0].nameFr, 
                acteCode: props.depKine[0].code, 
                acteId: props.depKine[0].id,
                description: ""
            }] : bonDetails.filter(elt => elt.acteId !== props.depKine[0].id);
        }
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    const saveDiagnosticTypeUpdates = (val) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        if(!consultation.kine && val){
            consultation.kine = {};
        }else{
            delete consultation['kine'];
        }
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    const removeLine = (e, index) => {
        e.preventDefault();
        setChoosenActes(choosenActes.filter((elt, i) => i !== index));
        setDescriptions(descriptions.filter((elt, i) => i !== index));
        setBonDetails(bonDetails.filter(elt => elt.acteId !== props.depKine[0]?.id).filter((elt, i) => i !== index));
    }

    const addLine = (e) => {
        e.preventDefault();
        setChoosenActes([...choosenActes, {
            acteName: '', 
            acteCode: '', 
            acteId: null, 
            description: ''
        }]);
        setDescriptions([...descriptions, '']);
        setBonDetails([...bonDetails.filter(elt => elt.acteId !== props.depKine[0]?.id), {
            acteName: '', 
            acteCode: '', 
            acteId: null, 
            description: ''
        }]);
    }

    useEffect(() => {
        dispatch(searchActesDepKineAction("frais", props.currentUser.providerId, 'Frais de déplacement'));
    }, []);

    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Kinésithérapie</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='d-flex justify-content-start'>
                                        <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                            <input
                                            type='checkbox'
                                            checked={withKine}
                                            onChange={(e) => {
                                                setWithKine(e.target.checked);
                                                saveDiagnosticTypeUpdates(e.target.checked);
                                            }}
                                            className='custom-control-input'
                                            id='customCheckBox222'
                                            required
                                            />
                                            <label
                                            className='custom-control-label'
                                            htmlFor='customCheckBox222'
                                            >
                                            {t('kine.add-kine')}
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div className='d-flex justify-content-start'>
                                        <div className='custom-control custom-checkbox mb-3 checkbox-primary'>
                                            <input
                                            type='checkbox'
                                            checked={isDomicile}
                                            disabled={!withKine}
                                            onChange={(e) => {
                                                setIsDomicile(e.target.checked);
                                                saveDiagnosticUpdates(e.target.checked, 'isDomicile');
                                            }}
                                            className='custom-control-input'
                                            id='customCheckBox223'
                                            required
                                            />
                                            <label
                                            className='custom-control-label'
                                            htmlFor='customCheckBox223'
                                            >
                                            {t('kine.at-home')}
                                            </label>
                                        </div>
                                    </div>

                                    
                                    {bonDetails.filter(elt => elt.acteId !== props.depKine[0]?.id).map((actePrescrit, i) => {
                                        return <div className='row'>
                                            <div className='col-5'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('kine.actes')}<span className='text-danger'>*</span></strong>
                                                    </label>
                                                    <Select
                                                        className="select-search"
                                                        options={props.actesKine.map(elt => {return {label: elt.nameFr, value: elt.code, id: elt.id}})}
                                                        value={{label: choosenActes[i].acteName, value: choosenActes[i].acteCode, id: choosenActes[i].acteId}}
                                                        placeholder={t('kine.search-acts')}
                                                        search
                                                        isDisabled={!withKine}
                                                        onInputChange={(val) => {
                                                            if(val.length >= 1){
                                                                dispatch(searchActesSpecAction(val, props.currentUser.providerId, 'Acte spécialisé'));
                                                            }
                                                        }}
                                                        onChange={(val) => {
                                                            setChoosenActes(choosenActes.map((elt, k) => {
                                                                if(i === k) {
                                                                    elt = {
                                                                        acteName: val.label,
                                                                        acteCode: val.value, 
                                                                        acteId: val.id
                                                                    }
                                                                }
                                                                return elt;
                                                            }))
                                                            let temp = bonDetails;
                                                            temp[i] = {
                                                                acteName: val.label, 
                                                                acteCode: val.value, 
                                                                acteId: val.id,
                                                                description: temp[i].description
                                                            }; 
                                                            setBonDetails(temp); 
                                                            saveDiagnosticUpdates(temp, 'bonDetails');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-5' >
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('kine.comment')}</strong>
                                                    </label>
                                                    <textarea
                                                        value={descriptions[i]}
                                                        rows={1}
                                                        className='form-control input-default '
                                                        disabled={!withKine}
                                                        onChange={e => {
                                                            setDescriptions(descriptions.map((elt, j) => {
                                                                if(i === j) {
                                                                    elt = e.target.value
                                                                }
                                                                return elt;
                                                            }))
                                                            let temp = bonDetails;
                                                            temp[i].description = e.target.value;
                                                            setBonDetails(temp); 
                                                            saveDiagnosticUpdates(temp, 'bonDetails');
                                                        }} 
                                                        placeholder="commentaire"
                                                    />
                                                </div>
                                            </div>
                                            {(bonDetails.filter(elt => elt.acteId !== props.depKine[0]?.id).length > 1)  && <div className='col-1' style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                            }}>
                                                <button className='btn btn-outline-danger' disabled={!withKine} onClick={e => removeLine(e, i)}>
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            </div>}
                                            {(i === bonDetails.filter(elt => elt.acteId !== props.depKine[0]?.id).length - 1)  && <div className='col-1' style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                            }}>
                                                <button className='btn btn-outline-success' disabled={!withKine} onClick={e => addLine(e)}>
                                                    <i className='fa fa-plus'></i>
                                                </button>
                                            </div>}
                                        </div>
                                    })}

                                    <div className='row justify-content-end'>
                                        <button className='button button-primary' value={t('kine.add-act')}></button>
                                    </div>
                                    
                                    <div className='row'>

                                        <div className='col-3'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.type-ordonance')}<span className='text-danger'>*</span></strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={typesTraitement}
                                                    value={{label: typeOrdonance, value: typeOrdonance}}
                                                    placeholder={t('kine.search-acts')}
                                                    search
                                                    isDisabled={!withKine}
                                                    onChange={(val) => {
                                                        setTypeOrdonance(val.value);
                                                        saveDiagnosticUpdates(val.value, 'typeOrdonance');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.total-seances')}<span className='text-danger'>*</span></strong>
                                                </label>
                                                <input
                                                value={nombreSession}
                                                type='number'
                                                className='form-control input-default '
                                                disabled={!withKine}
                                                onChange={e => {
                                                    setNombreSession(e.target.value);
                                                    saveDiagnosticUpdates(e.target.value, 'nombreSession');
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.frequence')}<span className='text-danger'>*</span></strong>
                                                </label>
                                                <input
                                                value={frequence}
                                                type='number'
                                                className='form-control input-default '
                                                disabled={!withKine}
                                                onChange={e => {
                                                    setFrequence(e.target.value);
                                                    saveDiagnosticUpdates(e.target.value, 'frequence');
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('kine.unite-frequence')}<span className='text-danger'>*</span></strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={frequences}
                                                    value={{label: uniteFrequence, value: uniteFrequence}}
                                                    placeholder={t('kine.search-acts')}
                                                    search
                                                    isDisabled={!withKine}
                                                    onChange={(val) => {
                                                        setUniteFrequence(val.value);
                                                        saveDiagnosticUpdates(val.value, 'uniteFrequence');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => {
    return {
        actesKine: state.provider.actesSpec,
        depKine: state.provider.depKine
    };
};

export default connect(mapStateToProps)(KineForm);
