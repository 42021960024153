import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";
import { withRouter } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { getBonDataByCodeAction, startLoader, executeBonAction } from '../../../store/actions/KineActions';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { useReactToPrint } from 'react-to-print';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import './Seances.css';

const Seances = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [numSeance, setNumSeance] = useState(1);
    const [dateSeance, setDateSeance] = useState(new Date());
    const [suiviData, setSuiviData] = useState([]);
    const { bonData, currentUser, history, errorMessage, successMessage, showLoading } = props;

    const componentRef = useRef();

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonDataByCodeAction(search, currentUser.providerId));
        }
    }

    const saveSeance = (e) => {
        e.preventDefault();
        let data = {
            kineBonId: bonData.id,
            executedBy: currentUser.providerId,
            exectutedName: currentUser.provider.providerName,
            executedDate: dateSeance
        }
        dispatch(startLoader());
        dispatch(executeBonAction(data));
    }

    const statusBodyTemplate = (rowData) => {
        return <div>
            {rowData.isPatientValidate && <span className="text-success">
                Validé
            </span>}
            {!rowData.isPatientValidate && <span className="text-danger">
                Non Validé
            </span>}
        </div>
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENOPTIC') && canDo('MENOPTIC', 'ACVIEWBONOPTIC')){
            if(Object.keys(bonData).length > 0){
                setNumSeance(bonData.kineBonSeances.length + 1);
                let allDatas = [];
                for(let i = 1; i <= bonData.nombreSession; i++){
                    let elt = {
                        numSeance: i,
                        executedDate: "",
                        executedName: "",
                        signaturePatient: ""
                    }
                    allDatas.push(elt);
                }
                setSuiviData(allDatas);
            }
        }else{
            props.history.push('/access-denied');
        }
    }, [bonData]);

    useEffect(() => {
        if(search.trim().length > 0) {
            dispatch(startLoader());
            dispatch(getBonDataByCodeAction(search));
        }
    }, [successMessage, errorMessage]);
    

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='no-print row justify-content-center mt-4 '>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder={t('common.bon-search-placeholder')} required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">Un instant...</span>
                                </Button>
                            </div> }
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                {Object.keys(bonData).length > 0 &&  <>
                    <div className='no-print'>
                        <h2 className='text-center mb-5'>SEANCES DE KINESITHERAPIE</h2>
                        <h4 className='text-center'>
                            <b className='text-warning text-center'>{t('statuses.'+bonData.status)}</b>
                        </h4>
                        <div className='row justify-content-start'>
                            <div className='col-6 text-right'>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>Patient:</span>
                                    <span className='text-bold text-primary ml-2'>{bonData.prescription?.consultation?.beneficiaryName}</span>
                                </h4>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                                    <span className='text-bold text-primary ml-2'>{bonData.prescription?.consultation?.providerName}</span>
                                </h4>
                            </div>
                        </div>
                        <div className="border-top my-2"></div>

                        <div className='row'>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Type: </strong>
                                    <span className='text-primary'>
                                        {bonData.typeOrdonance}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Nombre de séances prescrites: </strong>
                                    <span className='text-primary'>
                                        {bonData.nombreSession}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Nombre de séances restantes: </strong>
                                    <span className='text-primary'>
                                        {bonData.nombreSession}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Fréquence: </strong>
                                    <span className='text-primary'>
                                        {bonData.frequence}/{bonData.uniteFrequence}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>A domicile: </strong>
                                    <span className='text-primary'>
                                        {bonData.isDomicile ? t('kine.yes') : t('kine.no')}
                                    </span>
                                </label>
                            </div>
                        </div>
                        
                        {bonData.kineBonSeances.length < bonData.nombreSession && <div className='row justify-content-center mt-4'>
                            <div className='basic-form' style={{width: "100%"}}>
                                <form onSubmit={(e) => saveSeance(e)}>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-5'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('kine.num-seance')}<span className='text-danger'>*</span></strong>
                                                    </label>
                                                    <input
                                                    value={numSeance}
                                                    type='text'
                                                    disabled
                                                    className='form-control input-default '
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-5'>
                                                <div className='form-group'>
                                                    <label className='mb-1 '>
                                                        <strong>{t('kine.date')}<span className='text-danger'>*</span></strong>
                                                    </label>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                            autoOk
                                                            label=""
                                                            clearable
                                                            format="dd/MM/yyyy"
                                                            value={dateSeance}
                                                            disabled={showLoading}
                                                            required
                                                            onChange={setDateSeance}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2 justify-content-center mt-4'>
                                            { showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('appointment.await-text')}</span>
                                                </Button> }
                                            { !showLoading &&
                                                <input type='submit' value={t('common.confirm')} className='btn btn-primary btn-block'/>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>}

                        <div className="card">
                            <DataTable value={bonData.kineBonSeances} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                <Column field="numSeance" header="Numéro Séance" style={{ minWidth: '200px' }}></Column>
                                <Column field="executedDate" header="Date d'exécution" style={{ minWidth: '200px' }}></Column>
                                <Column field="executedName" header="Exécutant" style={{ minWidth: '150px' }}></Column>
                                <Column field="isPatientValidate" header="Statut" style={{ minWidth: '150px' }} body={statusBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        
                        <div className='col-2 justify-content-center mt-4'>
                            <button value={t('common.print-suivi')} className='btn btn-info btn-block' onClick={e => window.print()}>
                                {t('common.print-suivi')}
                            </button>
                        </div>
                    </div>
                    <div className='fiche-suivi'>
                        <h2 className='text-center mb-5'>FICHE DE SUIVI KINESITHERAPIE - {currentUser.provider.providerName}</h2>
                        
                        <div className='row justify-content-between'>
                            <div className='col-6'>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>Patient:</span>
                                    <span className='text-bold text-primary ml-2'>{bonData.prescription?.consultation?.beneficiaryName}</span>
                                </h4>
                            </div>
                            <div className='col-6' style={{textAlign: 'right'}}>
                                <h4 className='mt-2 text-dark text-start'>
                                    <span style={{textDecoration: 'underline'}}>Prescripteur:</span>
                                    <span className='text-bold text-primary ml-2'>{bonData.prescription?.consultation?.providerName}</span>
                                </h4>
                            </div>
                        </div>
                        <div className="border-top my-2"></div>

                        <div className='row'>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Type: </strong>
                                    <span className='text-primary'>
                                        {bonData.typeOrdonance}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Nombre de séances prescrites: </strong>
                                    <span className='text-primary'>
                                        {bonData.nombreSession}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Nombre de séances restantes: </strong>
                                    <span className='text-primary'>
                                        {bonData.nombreSession}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>Fréquence: </strong>
                                    <span className='text-primary'>
                                        {bonData.frequence}/{bonData.uniteFrequence}
                                    </span>
                                </label>
                            </div>
                            <div className='form-group col-4'>
                                <label className='mb-1 '>
                                    <strong>A domicile: </strong>
                                    <span className='text-primary'>
                                        {bonData.isDomicile ? t('kine.yes') : t('kine.no')}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable value={suiviData} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                <Column field="numSeance" header="Numéro Séance" style={{ minWidth: '200px' }}></Column>
                                <Column field="executedDate" header="Date d'exécution" style={{ minWidth: '200px' }}></Column>
                                <Column field="executedName" header="Exécutant" style={{ minWidth: '150px' }}></Column>
                                <Column field="signaturePatient" header="Signature du patient" style={{ minWidth: '150px' }}></Column>
                            </DataTable>
                        </div>

                    </div>
                </>}
                
            </div>
        </Fragment>
    )
};
const mapStateToProps = (state) => {
    return {
       bonData: state.kine.bonData,
       successMessage: state.kine.successMessage,
       errorMessage: state.kine.errorMessage,
       showLoading: state.kine.showLoading,
       currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(Seances));