import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { addProviderBank } from '../../../../store/actions/ProviderActions';
import { Button, Modal } from "react-bootstrap";

export const BankInfosModal = (props) => {
    const dispatch = useDispatch();
    const { providerRef, manageBankModal, history } = props;
    const [bankName, setBankName] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.bankName || "");
    const [bankCode, setBankCode] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.bankCode || "");
    const [swift, setSwift] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.swift || "");
    const [numeroBanque, setNumeroBanque] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.numeroBanque || "");
    const [cleRib, setCleRib] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.cleRib || "");
    const [intituleBanque, setIntituleBanque] = useState(providerRef.bankDetails[providerRef.bankDetails.length - 1]?.intituleBanque || "");
    const [fileSrc, setFileSrc] = useState(null);
 
    const [paymentMeans, setPaymentMeans] = useState(providerRef.mobilePaymentDetails || []);

    const fromProvider = true;

    const saveProviderBankInfos = () => {

        const data = {
            providerId: providerRef.id,
            bankDetail: {
                bankName,
                fileSrc,
                bankCode,
                swift,
                rib: cleRib,
                accountName: intituleBanque,
                accountNumber: numeroBanque,
            },
            paymentMeans,
            fromProvider,
        };


        dispatch(addProviderBank(data, history));
        manageBankModal(false, true);

    }

    const checkFormValidity = () => {
        if(bankName.length === 0 && bankCode.length === 0 && swift.length === 0 &&
            numeroBanque.length === 0) {
                return true;
        }

        if(bankName.length === 0) {
            return true;
        }

        if(bankName.length > 0 && (bankCode.length === 0 || swift.length === 0 || numeroBanque.length === 0)){
            return true;
        }

        return false
    }

    const addRIB = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setFileSrc(upFiles[keys[0]]);
    }

    const loadIdentityFile = (e, paymentMean) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setPaymentMeans(paymentMeans.map(payM => { 
            if(payM.id === paymentMean.id){
                payM.file = upFiles[keys[0]];
            }
            return payM;
        }));
    }

    return (
            <>
                <Modal.Header>
                    <Modal.Title>{providerRef.bankDetails.length > 0 ? "Modification des informations bancaires" : "Création des informations bancaires"}</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => manageBankModal(false, false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <h5 className='mt-4 text-primary'>Banque</h5>
                    <div className="border-top mt-2 mb-4"></div>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-4 form-group'>
                            <label className='mb-1 '>
                                <strong>Banque</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={bankName}
                                onChange={(e) => setBankName(e.target.value)}
                            />
                        </div>
                        <div className='col-4 form-group'>
                            <label className='mb-1 '>
                                <strong>Intitulé du compte</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={intituleBanque}
                                onChange={(e) => setIntituleBanque(e.target.value)}
                            />
                        </div>
                        <div className='col-4 form-group'>
                            <label className='mb-1 '>
                                <strong>RIB</strong>
                            </label>
                            <div>
                                <div className='input-group'>
                                    <div className='custom-file'>
                                        <input type="file" required={bankName.length > 0} className='custom-file-input' onChange={(e) => addRIB(e)} />
                                        <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-3 form-group'>
                            <label className='mb-1 '>
                                <strong>Numéro de compte</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={numeroBanque}
                                required={bankName.length > 0}
                                disabled={bankName.length === 0}
                                onChange={(e) => setNumeroBanque(e.target.value)}
                            />
                        </div>
                        <div className='col-3 form-group'>
                            <label className='mb-1 '>
                                <strong>Code Banque</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={bankCode}
                                required={bankName.length > 0}
                                disabled={bankName.length === 0}
                                onChange={(e) => setBankCode(e.target.value)}
                            />
                        </div>
                        <div className='col-3 form-group'>
                            <label className='mb-1 '>
                                <strong>Cle RIB</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={cleRib}
                                required={bankName.length > 0}
                                disabled={bankName.length === 0}
                                onChange={(e) => setCleRib(e.target.value)}
                            />
                        </div>
                        <div className='col-3 form-group'>
                            <label className='mb-1 '>
                                <strong>Code Swift</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={swift}
                                required={bankName.length > 0}
                                disabled={bankName.length === 0}
                                onChange={(e) => setSwift(e.target.value)}
                            />
                        </div>
                    </div>

                    <h5 className='mt-4 text-primary'>Intégrateurs Mobile</h5>
                    <div className="border-top mt-2 mb-4"></div>
                    {
                        paymentMeans.map((paymentMean) => {
                            return <>
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Opérateur</strong>
                                        </label>
                                        <select
                                            defaultValue={paymentMean.operator}
                                            className='form-control form-control-lg'
                                            onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.operator = e.target.value;
                                                    }
                                                    return payM;
                                                }))
                                            }
                                        >
                                            <option value='option'>
                                                choisir...
                                            </option>
                                            <option value='om'>Orange Money</option>
                                            <option value='momo'>MTN Mobile Money</option>
                                        </select>
                                    </div>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Numéro de Téléphone</strong>
                                        </label>
                                        <input type='phone' className='form-control'
                                            value={paymentMean.phonenumber}
                                            required={paymentMean.operator.length > 0}
                                            onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.phonenumber = e.target.value;
                                                    }
                                                    return payM;
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Nom complet du propriétaire</strong>
                                        </label>
                                        <input type='phone' className='form-control'
                                            value={paymentMean.beneficialName}
                                            required={paymentMean.operator.length > 0}
                                            onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.beneficialName = e.target.value;
                                                    }
                                                    return payM;
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                
                                <div className='row justify-content-between align-items-center'>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Type de document d'identité</strong>
                                        </label>
                                        <select
                                            defaultValue={paymentMean.idType}
                                            className='form-control form-control-lg'
                                            required={paymentMean.operator.length > 0}
                                            onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.idType = e.target.value;
                                                    }
                                                    return payM;
                                                }))
                                            }
                                        >
                                            <option value='option'>
                                                choisir...
                                            </option>
                                            <option value='cni'>CNI</option>
                                            <option value='passeport'>Passeport</option>
                                            <option value='contribuable'>Contribuable</option>
                                        </select>
                                    </div>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Numéro d'identifiant</strong>
                                        </label>
                                        <input type='phone' className='form-control'
                                            value={paymentMean.idNumber}
                                            required={paymentMean.operator.length > 0}
                                            onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.idNumber = e.target.value;
                                                    }
                                                    return payM;
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className='col-4 form-group'>
                                        <label className='mb-1 '>
                                            <strong>Document d'identité</strong>
                                        </label>
                                        <div>
                                            <div className='input-group'>
                                                <div className='custom-file'>
                                                    <input type="file" required={paymentMean.operator.length > 0} className='custom-file-input' onChange={(e) => loadIdentityFile(e, paymentMean)} />
                                                    <label className='custom-file-label'>{paymentMean.file ? <small>{paymentMean.file.name}</small> : 'Choisir'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })
                    }
                    
                    <div className='row justify-content-end align-items-center'>
                        <Button
                            variant="outline-secondary"
                            className="sm"
                            onClick={() => setPaymentMeans([...paymentMeans, 
                                {operator: "", phonenumber: "", beneficialName: "", idType: "", idNumber: "", file: null}])}
                        >
                            + Ajouter
                        </Button>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger light"
                        onClick={() => manageBankModal(false)}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant=""
                        type="button"
                        className="btn btn-primary"
                        disabled={checkFormValidity()}
                        onClick={() => saveProviderBankInfos()}
                    >
                        Enregistrer
                    </Button>
                </Modal.Footer>
            </>
    )
};

export default BankInfosModal;