import {getConsultations, getConsultation, requestConsultation, searchDrugs, searchDiseases, getSuggestion, updateConsultation, getClientConsultations, getClientMedicalFile, getParameterHistory,
    requestAppointment, listAppointments, getAppointment, listDoctorAppointments, updateParameters, getActesConsultation, getActesHospitalisation,
    addActeSpecDetail, addExamenDetail, addPharmacieDetail, addHospitalisationDetail, requestProlongHospi, endHospitalisation,
    getActByType, changeHospiRoom, getOptiqueParams, getConsultationByCode
} from '../../services/ConsultationService';
import uploadFiles from '../../services/BlobStorageService';
import { getPatientResultsFiles } from '../../services/LaboratoireService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_CONSULTATIONS_CONFIRMED_ACTION = '[getConsultations action] confirmed getConsultations';
export const GET_CONSULTATIONS_FAILED_ACTION = '[getConsultations action] failed getConsultations';

export const GET_CONSULTATION_DATA_CONFIRMED_ACTION = '[getConsultation action] confirmed getConsultation';
export const GET_CONSULTATION_DATA_FAILED_ACTION = '[getConsultation action] failed getConsultation';

export const REQUEST_CONSULTATION_CONFIRMED_ACTION = '[requestConsultation action] confirmed requestConsultation';
export const REQUEST_CONSULTATION_FAILED_ACTION = '[requestConsultation action] failed requestConsultation';


export const SEARCH_DRUGS_CONFIRMED_ACTION = '[searchDrugs action] confirmed searchDrugs';
export const SEARCH_DRUGS_FAILED_ACTION = '[searchDrugs action] failed searchDrugs';


export const SEARCH_DISEASES_CONFIRMED_ACTION = '[searchDiseases action] confirmed searchDiseases';
export const SEARCH_DISEASES_FAILED_ACTION = '[searchDiseases action] failed searchDiseases';


export const GET_SUGGESTIONS_CONFIRMED_ACTION = '[getSuggestion action] confirmed getSuggestion';
export const GET_SUGGESTIONS_FAILED_ACTION = '[getSuggestion action] failed getSuggestion';

export const UPDATE_CONSULTATION_CONFIRMED_ACTION = '[updateConsultation action] confirmed updateConsultation';
export const UPDATE_CONSULTATION_FAILED_ACTION = '[updateConsultation action] failed updateConsultation';

export const GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION = '[getClientConsultations action] confirmed getClientConsultations';
export const GET_CLIENT_CONSUTATIONS_FAILED_ACTION = '[getClientConsultations action] failed getClientConsultations';

export const GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION = '[getClientMedicalFile action] confirmed getClientMedicalFile';
export const GET_CLIENT_MEDICAL_FILE_FAILED_ACTION = '[getClientMedicalFile action] failed getClientMedicalFile';

export const GET_PARAMS_HISTORY_CONFIRMED_ACTION = '[getParamsHistory action] confirmed getParamsHistory';
export const GET_PARAMS_HISTORY_FAILED_ACTION = '[getParamsHistory action] failed getParamsHistory';

export const REQUEST_APPOINTMENT_CONFIRMED_ACTION = '[requestAppointment action] confirmed requestAppointment';
export const REQUEST_APPOINTMENT_FAILED_ACTION = '[requestAppointment action] failed requestAppointment';

export const LIST_APPOINTMENTS_CONFIRMED_ACTION = '[listAppointments action] confirmed listAppointments';
export const LIST_APPOINTMENTS_FAILED_ACTION = '[listAppointments action] failed listAppointments';

export const GET_APPOINTMENT_CONFIRMED_ACTION = '[getAppointment action] confirmed getAppointment';
export const GET_APPOINTMENT_FAILED_ACTION = '[getAppointment action] failed getAppointment';

export const UPDATE_PARAMETERS_CONFIRMED_ACTION = '[updateParameters action] confirmed updateParameters';
export const UPDATE_PARAMETERS_FAILED_ACTION = '[updateParameters action] failed updateParameters';

export const GET_ACTES_CONSULTATION_CONFIRMED_ACTION = '[getActesConsultation action] confirmed getActesConsultation';
export const GET_ACTES_CONSULTATION_FAILED_ACTION = '[getActesConsultation action] failed getActesConsultation';

export const GET_ACTES_HOSPITALISATION_CONFIRMED_ACTION = '[getActesHospitalisation action] confirmed getActesHospitalisation';
export const GET_ACTES_HOSPITALISATION_FAILED_ACTION = '[getActesHospitalisation action] failed getActesHospitalisation';

export const ADD_ACTE_DETAIL_CONFIRMED_ACTION = '[addActeDetail action] confirmed addActeDetail';
export const ADD_ACTE_DETAIL_FAILED_ACTION = '[addActeDetail action] failed addActeDetail';

export const REQUEST_PROLONG_HOSPI_CONFIRMED_ACTION = '[requestProlongHospi action] confirmed requestProlongHospi';
export const REQUEST_PROLONG_HOSPI_FAILED_ACTION = '[requestProlongHospi action] failed requestProlongHospi';

export const CHANGE_HOSPI_ROOM_CONFIRMED_ACTION = '[changeHospiRoom action] confirmed changeHospiRoom';
export const CHANGE_HOSPI_ROOM_FAILED_ACTION = '[changeHospiRoom action] failed changeHospiRoom';

export const END_HOSPI_CONFIRMED_ACTION = '[endHospi action] confirmed endHospi';
export const END_HOSPI_FAILED_ACTION = '[endHospi action] failed endHospi';

export const GET_ACTES_BY_TYPE_CONFIRMED_ACTION = '[getActesByType action] confirmed getActesByType';
export const GET_ACCESSOIRE_VERRE_CONFIRMED_ACTION = '[getAccessoireVerre action] confirmed getAccessoireVerre';
export const GET_ACTES_BY_TYPE_FAILED_ACTION = '[getActesByType action] failed getActesByType';
export const GET_OPTIC_PARAMS_CONFIRMED_ACTION = '[getOpticParams action] confirmed getOpticParams';

export const GET_CONSULTATION_BY_CODE_DATA_CONFIRMED_ACTION = '[getConsultationByCode action] confirmed getConsultationByCode';
export const GET_CONSULTATION_BY_CODE_DATA_FAILED_ACTION = '[getConsultationByCode action] failed getConsultationByCode';

export const START_LOADER_ACTION = '[consultationLoader action] started consultationLoader';

var I18n = require('react-redux-i18n').I18n;

export const getConsultationsAction = (providerId, patientId = null) => {
   
   return (dispatch) => {
       getConsultations(providerId)
       .then((response) => {
           
           dispatch(confirmedGetConsultationsAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getConsult.error')+error.response?.data?.message);
           dispatch(getConsultationsFailedAction(error.response?.data?.message));
       });
   };
};

export const getConsultationDataAction = (id) => {
   
   return (dispatch) => {
       getConsultation(id)
       .then((response) => {
           
           dispatch(confirmedGetConsultationAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getConsultData.error')+error.response?.data?.message);
           dispatch(getConsultationFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};


export const makeConsultationRequest = (data, history) => {
   
   return (dispatch) => {
       requestConsultation(data)
       .then((response) => {
           
           dispatch(confirmedRequestConsultationAction());
           history.push('/consultation-list');
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.makeConsult.error')+error.response?.data?.message);
           dispatch(requestConsultationFailedAction(error.response?.data?.message));
       });
   };
}

export const searchDrugsAction = (keyword) => {
   
   return (dispatch) => {
       searchDrugs(keyword)
       .then((response) => {
           
           dispatch(confirmedSearchDrugsAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.searchDrug.error')+error.response?.data?.message);
           dispatch(searchDrugsFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};

export const searchDiseasesAction = (keyword) => {
   
   return (dispatch) => {
       searchDiseases(keyword)
       .then((response) => {
           
           dispatch(confirmedSearchDiseasesAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.searchDisease.error')+error.response?.data?.message);
           dispatch(searchDiseasesFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};


export const getSuggestionsDataAction = (type) => {
   
   return (dispatch) => {
       getSuggestion(type)
       .then((response) => {
           
           dispatch(confirmedGetSuggestionsAction(response.data, type));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getSuggest.error')+error.response?.data?.message);
           dispatch(getSuggestionsFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};

export const updateConsultationAction = (data, history) => {
   
   return async (dispatch) => {
        let ordonanceFile = await uploadFiles([data.fileSrc], 'amp-providers-container');
        data.ordonnanceUrl = ordonanceFile[0];
        delete data.fileSrc;

       updateConsultation(data)
       .then((response) => {
           
           localStorage.removeItem("consultation")
           dispatch(confirmedUpdateConsultationAction());
           history.push('/consultation-list');
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.updateConsult.error')+error.response?.data?.message);
           dispatch(updateConsultationFailedAction(error.response?.data?.message));
       });
   };
}

export const getClientConsultationsAction = (patientId) => {
   
   return (dispatch) => {
       getClientConsultations(patientId)
       .then((response) => {
           
           dispatch(confirmedGetClientConsultationsAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getClientConsult.error')+error.response?.data?.message);
           dispatch(getClientConsultationsFailedAction(error.response?.data?.message));
       });
   };
};

export const getClientMedicalFileAction = (patientId) => {
   return (dispatch) => {
       getClientMedicalFile(patientId)
       .then((response) => {
           
           let ans = response.data;
           getPatientResultsFiles(patientId)
           .then((resp) => {
               ans.analyseFiles = resp.data;
               dispatch(confirmedGetClientMedicalFileAction(ans));
           })
           .catch((error) => {
               errorToastr(I18n.t('consultation.medicalFile.error_one')+error.response?.data?.message);
               dispatch(getClientClientMedicalFileFailedAction(error.response?.data?.message));
           });
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.medicalFile.error_two')+error.response?.data?.message);
           dispatch(getClientClientMedicalFileFailedAction(error.response?.data?.message));
       });
   };
};

export const getParametersHistoryAction = (id, startDate, endDate) => {
   
   return (dispatch) => {
       getParameterHistory(id, startDate, endDate)
       .then((response) => {
           
           dispatch(confirmedGetParamsHistoryAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getPatientParam.error')+error.response?.data?.message);
           dispatch(getParamsHistoryFailedAction(error.response?.data?.message));
       });
   };
};

export const makeAppointmentRequest = (id, data, history) => {
   return (dispatch) => {
       requestAppointment(id, data)
       .then((response) => {
           
           dispatch(confirmedRequestAppointmentAction());
           history.push('/appointments-list');
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.makeAppointment.error')+error.response?.data?.message);
           dispatch(requestAppointmentFailedAction(error.response?.data?.message));
       });
   };
}

export const getAppointmentsAction = (id, type) => {
   
   if(type !== 'doctor') {
        return (dispatch) => {
            listAppointments(id)
            .then((response) => {
                
                dispatch(confirmedlistAppointmentsAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.getPatientAppointment.error')+error.response?.data?.message);
                dispatch(listAppointmentsFailedAction(error.response?.data?.message));
            });
        };
   }else{
        return (dispatch) => {
            listDoctorAppointments(id)
            .then((response) => {
                
                dispatch(confirmedlistAppointmentsAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.getPatientAppointment.error')+error.response?.data?.message);
                dispatch(listAppointmentsFailedAction(error.response?.data?.message));
            });
        };
   }
};

export const getAppointmentDataAction = (id, history) => {
   
   return (dispatch) => {
       getAppointment(id)
       .then((response) => {
           
           dispatch(confirmedGetAppointmentAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.getPatientAppointment.error_data')+error.response?.data?.message);
           dispatch(getAppointmentFailedAction(error.response?.data?.message));
           history.goBack();
       });
   };
};

export const updateParametersAction = (id, data) => {
   return (dispatch) => {
        updateParameters(id, data)
       .then((response) => {
           
           successToastr(I18n.t('consultation.updateparams.success'));
           dispatch(confirmedUpdateParametersAction());
       })
       .catch((error) => {
           errorToastr(I18n.t('consultation.updateparams.error')+error.response?.data?.message);
           dispatch(updateParametersFailedAction(error.response?.data?.message));
       });
   };
}

export const getActesConsultationAction = () => {
    
    return (dispatch) => {
        getActesConsultation()
        .then((response) => {
            
            dispatch(confirmedGetActesConsultationAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.getActesConsult.error')+error.response?.data?.message);
            dispatch(getActesConsultationFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getActesHospitalisationAction = (providerId) => {
     
     return (dispatch) => {
         getActesHospitalisation(providerId)
         .then((response) => {
             
             dispatch(confirmedGetActesHospitalisation(response.data));
         })
         .catch((error) => {
             errorToastr(I18n.t('consultation.getActeHospi.error')+error.response?.data?.message);
             dispatch(getActesHospitalisationFailedAction(error.response?.data?.message));
         });
     };
  };

  export const addActeDetailAction = (type, data) => {
    
    return async (dispatch) => {
        if(type === "examens"){
            addExamenDetail(data)
            .then((response) => {
                
                dispatch(confirmedAddActeDetailAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.addActeDetail.error_one')+error.response?.data?.message);
                dispatch(addActeDetailFailedAction(error.response?.data?.message));
            });
        }else if(type === "actes spécialisés"){
            addActeSpecDetail(data)
            .then((response) => {
                
                dispatch(confirmedAddActeDetailAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.addActeDetail.error_two')+error.response?.data?.message);
                dispatch(addActeDetailFailedAction(error.response?.data?.message));
            });
        }else if(type === "hospitalisation"){
            addHospitalisationDetail(data)
            .then((response) => {
                
                dispatch(confirmedAddActeDetailAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.addActeDetail.error_three')+error.response?.data?.message);
                dispatch(addActeDetailFailedAction(error.response?.data?.message));
            });
        }else {
            let ordonanceFile = await uploadFiles([data.fileSrc], 'amp-providers-container');
            data.ordonnanceUrl = ordonanceFile[0];
            delete data.fileSrc;

            addPharmacieDetail(data)
            .then((response) => {
                
                dispatch(confirmedAddActeDetailAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.addActeDetail.error_four')+error.response?.data?.message);
                dispatch(addActeDetailFailedAction(error.response?.data?.message));
            });
        }
    };
 };

 export const requestProlongHospiAction = (id, data, extra) => {
    
    return (dispatch) => {
        requestProlongHospi(id, data, extra)
        .then((response) => {
            
            dispatch(confirmedRequestProlongHospiAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.hospiDel.error_prolong')+error.response?.data?.message);
            dispatch(requestProlongHospiFailedAction(error.response?.data?.message));
        });
    };
 };

 export const changeHospiRoomAction = (data) => {
    
    return (dispatch) => {
        changeHospiRoom(data)
        .then((response) => {
            
            dispatch(confirmedChangeHospiRoomAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.hospiDel.error_change')+error.response?.data?.message);
            dispatch(ChangeHospiRoomFailedAction(error.response?.data?.message));
        });
    };
 };

 export const endHospitalisationAction = (data) => {
    
    return (dispatch) => {
        endHospitalisation(data)
        .then((response) => {
            
            dispatch(confirmedEndHospiAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('consultation.hospiDel.error')+error.response?.data?.message);
            dispatch(endHospiFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getActesByTypeAction = (type) => {
     
     return (dispatch) => {
        getActByType(type)
         .then((response) => {
             
             if(type === "Accessoires verres"){
                dispatch(confirmedGetAccessoiresVerresAction(response.data));
             }else{
                dispatch(confirmedGetActesByTypeAction(response.data));
             }
         })
         .catch((error) => {
             errorToastr(I18n.t('consultation.getActeVerre.error')+error.response?.data?.message);
             dispatch(getActesByTypeFailedAction(error.response?.data?.message));
         });
     };
  };

  export const getOpticParamsAction = (type) => {
      
      return (dispatch) => {
        getOptiqueParams(type)
          .then((response) => {
              
              dispatch(confirmedGetOpticParamsAction(response.data));
          })
          .catch((error) => {
              errorToastr(I18n.t('consultation.getOpticParams.error')+error.response?.data?.message);
              //dispatch(getActesByTypeFailedAction(error.response?.data?.message));
          });
      };
   };

   export const getConsultationByCodeDataAction = (id) => {
        return (dispatch) => {
            getConsultationByCode(id)
            .then((response) => {
                
                dispatch(confirmedGetConsultationByCodeAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('consultation.getConsultData.error')+error.response?.data?.message);
                dispatch(getConsultationByCodeFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export function confirmedGetConsultationByCodeAction(payload) {
        return {
            type: GET_CONSULTATION_BY_CODE_DATA_CONFIRMED_ACTION,
            payload,
        };
    };

    export function getConsultationByCodeFailedAction(payload) {
        return {
            type: GET_CONSULTATION_BY_CODE_DATA_FAILED_ACTION,
            payload,
        };
    };

   export function confirmedGetOpticParamsAction(payload) {
      return {
          type: GET_OPTIC_PARAMS_CONFIRMED_ACTION,
          payload,
      };
   };
 

  export function confirmedGetAccessoiresVerresAction(payload) {
     return {
         type: GET_ACCESSOIRE_VERRE_CONFIRMED_ACTION,
         payload,
     };
  };

  export function confirmedGetActesByTypeAction(payload) {
     return {
         type: GET_ACTES_BY_TYPE_CONFIRMED_ACTION,
         payload,
     };
  };
  
  export function getActesByTypeFailedAction(payload) {
     return {
         type: GET_ACTES_BY_TYPE_FAILED_ACTION,
         payload,
     };
  };
 

 export function confirmedEndHospiAction(payload) {
    return {
        type: END_HOSPI_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function endHospiFailedAction(payload) {
    return {
        type: END_HOSPI_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedRequestProlongHospiAction(payload) {
    return {
        type: REQUEST_PROLONG_HOSPI_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function requestProlongHospiFailedAction(payload) {
    return {
        type: REQUEST_PROLONG_HOSPI_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedChangeHospiRoomAction(payload) {
    return {
        type: CHANGE_HOSPI_ROOM_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function ChangeHospiRoomFailedAction(payload) {
    return {
        type: CHANGE_HOSPI_ROOM_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedAddActeDetailAction(payload) {
    return {
        type: ADD_ACTE_DETAIL_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function addActeDetailFailedAction(payload) {
    return {
        type: ADD_ACTE_DETAIL_FAILED_ACTION,
        payload,
    };
 };

  export function confirmedGetActesHospitalisation(payload) {
     return {
         type: GET_ACTES_HOSPITALISATION_CONFIRMED_ACTION,
         payload,
     };
  };
  
  export function getActesHospitalisationFailedAction(payload) {
     return {
         type: GET_ACTES_HOSPITALISATION_FAILED_ACTION,
         payload,
     };
  };

 export function confirmedGetActesConsultationAction(payload) {
    return {
        type: GET_ACTES_CONSULTATION_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getActesConsultationFailedAction(payload) {
    return {
        type: GET_ACTES_CONSULTATION_FAILED_ACTION,
        payload,
    };
 };

export function confirmedUpdateParametersAction(payload) {
    return {
        type: UPDATE_PARAMETERS_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateParametersFailedAction(payload) {
    return {
        type: UPDATE_PARAMETERS_FAILED_ACTION,
        payload,
    };
 };


export function confirmedGetConsultationsAction(payload) {
   return {
       type: GET_CONSULTATIONS_CONFIRMED_ACTION,
       payload,
   };
};

export function getConsultationsFailedAction(payload) {
   return {
       type: GET_CONSULTATIONS_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetConsultationAction(payload) {
   return {
       type: GET_CONSULTATION_DATA_CONFIRMED_ACTION,
       payload,
   };
};

export function getConsultationFailedAction(payload) {
   return {
       type: GET_CONSULTATION_DATA_FAILED_ACTION,
       payload,
   };
};

export function startLoader() {
   return {
       type: START_LOADER_ACTION
   };
};

export function confirmedRequestConsultationAction() {
   return {
       type: REQUEST_CONSULTATION_CONFIRMED_ACTION
   };
};

export function requestConsultationFailedAction(payload) {
   return {
       type: REQUEST_CONSULTATION_FAILED_ACTION,
       payload,
   };
};

export function confirmedSearchDrugsAction(payload) {
   return {
       type: SEARCH_DRUGS_CONFIRMED_ACTION,
       payload,
   };
};

export function searchDrugsFailedAction(payload) {
   return {
       type: SEARCH_DRUGS_FAILED_ACTION,
       payload,
   };
};

export function confirmedSearchDiseasesAction(payload) {
   return {
       type: SEARCH_DISEASES_CONFIRMED_ACTION,
       payload,
   };
};

export function searchDiseasesFailedAction(payload) {
   return {
       type: SEARCH_DISEASES_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetSuggestionsAction(data, typeData) {
   return {
       type: GET_SUGGESTIONS_CONFIRMED_ACTION,
       payload: {data, typeData},
   };
};

export function getSuggestionsFailedAction(payload) {
   return {
       type: GET_SUGGESTIONS_FAILED_ACTION,
       payload,
   };
};

export function confirmedUpdateConsultationAction() {
   return {
       type: UPDATE_CONSULTATION_CONFIRMED_ACTION
   };
};

export function updateConsultationFailedAction(payload) {
   return {
       type: UPDATE_CONSULTATION_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetClientConsultationsAction(payload) {
   return {
       type: GET_CLIENT_CONSUTATIONS_CONFIRMED_ACTION,
       payload,
   };
};

export function getClientConsultationsFailedAction(payload) {
   return {
       type: GET_CLIENT_CONSUTATIONS_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetClientMedicalFileAction(payload) {
   return {
       type: GET_CLIENT_MEDICAL_FILE_CONFIRMED_ACTION,
       payload,
   };
};

export function getClientClientMedicalFileFailedAction(payload) {
   return {
       type: GET_CLIENT_MEDICAL_FILE_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetParamsHistoryAction(payload) {
   return {
       type: GET_PARAMS_HISTORY_CONFIRMED_ACTION,
       payload,
   };
};

export function getParamsHistoryFailedAction(payload) {
   return {
       type: GET_PARAMS_HISTORY_FAILED_ACTION,
       payload,
   };
};

export function confirmedRequestAppointmentAction() {
   return {
       type: REQUEST_APPOINTMENT_CONFIRMED_ACTION
   };
};

export function requestAppointmentFailedAction(payload) {
   return {
       type: REQUEST_APPOINTMENT_FAILED_ACTION,
       payload,
   };
};

export function confirmedlistAppointmentsAction(payload) {
   return {
       type: LIST_APPOINTMENTS_CONFIRMED_ACTION,
       payload,
   };
};

export function listAppointmentsFailedAction(payload) {
   return {
       type: LIST_APPOINTMENTS_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetAppointmentAction(payload) {
   return {
       type: GET_APPOINTMENT_CONFIRMED_ACTION,
       payload,
   };
};

export function getAppointmentFailedAction(payload) {
   return {
       type: GET_APPOINTMENT_FAILED_ACTION,
       payload,
   };
};