import React, { useState, useEffect } from 'react';
import { Badge, ListGroup } from "react-bootstrap";
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from 'react-select';
import { searchActesAction } from '../../../../../store/actions/ProviderActions';
import { useTranslation } from "react-i18next";

const ExamsForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const consult = JSON.parse(localStorage.getItem("consultation"));

    const [examens, setExamens] = useState((consult && consult.examens) ? consult.examens : []);
    const [keyword, setKeyword] = useState("");

    const selectExam = (e, val) => {
        e.preventDefault();
        let selectedExam = props.examensList.filter(ex => {return ex.code === val.code})[0];
        if(examens.filter(act => {return act.code === selectedExam.code}).length === 0) {
            setExamens([...examens, selectedExam]);
            saveUpdates([...examens, selectedExam]);
        }
    }

    const removeElt = (val, e, type) => {
        if(e.type === "click" && type === "remove") {
            setExamens(examens.filter(diag => {return diag.code !== val}));
            saveUpdates(examens.filter(diag => {return diag.code !== val}));
        }
    }

    const saveUpdates = (data) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.examens = data;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchActesAction(keyword, props.currentUser.providerId, 'Examen'));
        }else{
            dispatch(searchActesAction('@@@'));
        }
    }, [keyword]);

    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Prescription Examens</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('consultation.search-exam')}</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.examensList.map(elt => {return {...elt, label: elt.nameFr+(elt.isAccord ? '(Accord Préalable)' : ''), value: elt.code}})}
                                                    value={examens.map(elt => {return {...elt, label: elt.nameFr+(elt.isAccord ? '(Accord Préalable)' : ''), value: elt.code}})}
                                                    placeholder={t('consultation.enter-keyword-exam')}
                                                    search
                                                    isMulti
                                                    onInputChange={(val) => {
                                                        if(val.length >= 1){
                                                            dispatch(searchActesAction(val, props.currentUser.providerId, 'Examen'));
                                                        }
                                                    }}
                                                    onChange={(vals) => {
                                                        setExamens(vals ? vals.map(val => {
                                                            delete val['label'];
                                                            delete val['value'];
                                                            return val;
                                                        }) : []);
                                                        saveUpdates(vals ? vals.map(val => {
                                                            delete val['label'];
                                                            delete val['value'];
                                                            return val;
                                                        }) : []);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Recherche l'examen</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-4' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height300"
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.examensList.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={examens.includes(disease)}
                                                                    key={j}
                                                                    onClick={e => selectExam(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className={"d-flex w-100 justify-content-between"}>
                                                                        <h6 className={examens.includes(disease) ? "text-white" : "text-dark"} >
                                                                            {disease.nameFr}
                                                                            {disease.isAccord && <small className="text-danger">(Accord Préalable)</small>}
                                                                        </h6>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <h5 className='mt-4 text-primary'>Examens</h5>
                                            <div className="border-top mt-2 mb-4"></div>
                                            {examens.map((diag, i) => {
                                                return (
                                                    <Badge variant="dark light mr-1 mb-1" key={i}>
                                                        <span>[{diag.code}] {' - '} {diag.nameFr}</span>
                                                        <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                            onClick={(e) => removeElt(diag.code, e, "remove")}
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </button>
                                                    </Badge>
                                                )
                                            })}
                                        </div>
                                    </div>*/}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        examensList: state.provider.actes
    };
};

export default connect(mapStateToProps)(ExamsForm);
