import React, { useState, useEffect } from 'react';
import { Badge, Dropdown, Button, ListGroup } from "react-bootstrap";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch } from 'react-redux';
import { searchDrugsAction } from '../../../../../store/actions/ConsultationActions';
import Select from 'react-select';
import './ConsultationStyle.css';
import { useTranslation } from "react-i18next";


const PharmacyForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));

    const units = [
        {value: "ml", label: "ml"},
        {value: "cl", label: "cl"},
        {value: "Cuillère à café", label: "Cuillère à café"},
        {value: "Cuillère à soupe", label: "Cuillère à soupe"},
        {value: "comprimé", label: "comprimé"},
        {value: "suppositoire", label: "suppositoire"},
        {value: "gouttes", label: "gouttes"},
    ]

    const frequency = [
        {value: "matin", label: "matin"},
        {value: "midi", label: "midi"},
        {value: "soir", label: "soir"},
        {value: "coucher", label: "coucher"},
        {value: "lever", label: "lever"},
        {value: "nuit", label: "nuit"},
        {value: "journée", label: "journée"},
        {value: "heure", label: "heure"},
        {value: "intervalle d'heure", label: "intervalle d'heure"},
    ];

    const durationUnits = [
        {value: "jour", label: "jour"},
        {value: "semaine", label: "semaine"},
        {value: "mois", label: "mois"},
    ];

    const meals = [
        {value: "", label: ""},
        {value: "avant", label: "avant"},
        {value: "pendant", label: "pendant"},
        {value: "après", label: "après"},
    ];

    const ways = [
        {value: "à boire", label: "à boire"},
        {value: "à avaler", label: "à avaler"},
        {value: "à aspirer par le nez", label: "à aspirer par le nez"},
        {value: "à appliquer sur la peau", label: "à appliquer sur la peau"},
        {value: "à appliquer dans l'oeil", label: "à appliquer dans l'oeil"},
        {value: "à appliquer sur la zone affectée", label: "à appliquer sur la zone affectée"},
        {value: "à injecter", label: "à injecter"},
        {value: "à goutter dans l'oeil", label: "à goutter dans l'oeil"},
        {value: "à goutter dans l'oreille", label: "à goutter dans l'oreille"},
        {value: "à insérer dans l'anus", label: "à insérer dans l'anus"}
    ];

    const [drugs, setDrugs] = useState((consult && consult.medocs) ? consult.medocs : []);
    const [drug, setDrug] = useState("");

    const [quantite, setQuantite] = useState(1);
    const [unit, setUnit] = useState(units[0].label);
    const [frequence, setFrequence] = useState(0);
    const [periode, setPeriode] = useState([frequency[0].label]);
    const [duration, setDuration] = useState(1);
    const [durationUnit, setDurationUnit] = useState(durationUnits[0].label);
    const [repas, setRepas] = useState(meals[0]);
    const [voie, setVoie] = useState(ways[0]);
    
    const [keyword, setKeyword] = useState("");

    const managePeriod = (val) => {
        if(val.includes("heure") || val.includes("intervalle d'heure")) {
            setPeriode([val[val.length - 1]]);
        }else{
            setPeriode(val);
            setFrequence(0);
        }
    }

    const composePosologie = (pos) => {
        if(pos.periode.includes("heure")) {
            return pos.quantite+' '+pos.unit+' à '+(pos.frequence)+' '+pos.periode.toString()+', '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
        else if(pos.periode.includes("intervalle d'heure")) {
            return pos.quantite+' '+pos.unit+' toute les '+(pos.frequence)+' heures, '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
        else{
            return pos.quantite+' '+pos.unit+' tous les '+pos.periode.toString()+', '+pos.voie+' ,'+(pos.repas ? pos.repas+' le repas ': '')+' durant '+pos.duration+' '+pos.durationUnit;
        }
    }

    const addPosologie = (e) => {
        e.preventDefault();
        let drugPosologie = {
            quantite,
            unit,
            frequence,
            periode: periode.toString(),
            duration,
            durationUnit,
            repas: repas.value,
            voie: voie.value,
        };

        setDrugs(drugs.map(drg => {
            if(drg.active) {
                drg.posologie = [...drg.posologie, drugPosologie];
                if(drg.posologieString && drg.posologieString.trim().length > 0){
                    drg.posologieString = drg.posologieString+', puis '+composePosologie(drugPosologie);
                }else{
                    drg.posologieString = composePosologie(drugPosologie);
                }
            }
            return drg;
        }));

        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.medocs = drugs;
        localStorage.setItem("consultation", JSON.stringify(consultation));

        setQuantite(1);
        setUnit(units[0].label);
        setFrequence(1);
        setPeriode([frequency[0].label]);
        setDuration(1);
        setDurationUnit(durationUnits[0].label);
        setRepas(meals[0]);
        setVoie(ways[0]);
    }

    const addDrugs = (e, val) => {
        e.preventDefault();
        let selectedDrug = props.drugList.filter(drg => { return drg.code === val.code})[0];

        if(drugs.filter(drg => { return drg.code === selectedDrug.code}).length === 0) {
            let newDrug = {
                active: true,
                id: selectedDrug.id,
                code: selectedDrug.code,
                name: selectedDrug.name,
                posologie: [],
                coverage: selectedDrug.isExclu,
                unit: selectedDrug.unit,
                quantity: 1
            }

            setDrugs([newDrug, ...drugs.map(drg => {
                drg.active = false;
                return drg;
            })]);

            saveUpdates();
        }

    }

    const removeDrug = (drugCode) => {
        setDrugs(drugs.filter(drg => { return drg.code !== drugCode}));
        saveUpdates();
    }

    const focusOnDrug = (drugCode, e) => {
        e.preventDefault();
        setDrugs(drugs.map(drg => {
            drg.active = drg.code === drugCode ? true : false;
            return drg;
        }));
    }

    const removePosologie = (e, drugCode, posPosition) => {
        e.preventDefault();
        setDrugs(drugs.map(drg => {
            if(drg.code === drugCode) {
                drg.posologie = drg.posologie.filter((pos, i) => {return i !== posPosition});
                let posStrArr = drg.posologieString.split(', puis ');
                posStrArr = posStrArr.filter((pos, i) => {return i !== posPosition});
                drg.posologieString = posStrArr.toString();
            }
            return drg;
        }));
        
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.medocs = drugs.map(drg => {
            if(drg.code === drugCode) {
                drg.posologie = drg.posologie.filter((pos, i) => {return i !== posPosition});
                let posStrArr = drg.posologieString.split(', puis ');
                posStrArr = posStrArr.filter((pos, i) => {return i !== posPosition});
                drg.posologieString = posStrArr.toString();
            }
            return drg;
        });
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchDrugsAction(keyword));
        }else{
            dispatch(searchDrugsAction('@@@'));
        }
    }, [keyword]);

    const saveUpdates = () => {
        let datas = {
            drugs,
        };
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.medocs = drugs;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }


    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>{t('consultation.pharmacy-prescription')}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='row '>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('consultation.search-drug')}</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.drugList.map(elt => {return {...elt, label: elt.name, value: elt.code}})}
                                                    value={drugs.map(elt => {return {...elt, label: elt.name, value: elt.code}})}
                                                    placeholder={t('consultation.enter-keyword-drug')}
                                                    search
                                                    isMulti
                                                    onInputChange={(val) => {
                                                        if(val.length >= 1){
                                                            dispatch(searchDrugsAction(val));
                                                        }
                                                    }}
                                                    onChange={(vals) => {
                                                        setDrugs(vals ? vals.map((val, i) => {
                                                            let res = {
                                                                active: i===vals.length - 1,
                                                                id: val.id,
                                                                code: val.code,
                                                                name: val.name,
                                                                posologie: [],
                                                                coverage: val.isExclu,
                                                                unit: val.unit,
                                                                quantity: 1
                                                            }
                                                            return res;
                                                        }) : []);
                                            
                                                        let consultation = JSON.parse(localStorage.getItem("consultation"));
                                                        consultation.medocs = vals ? vals.map((val, i) => {
                                                            let res = {
                                                                active: i===vals.length - 1,
                                                                id: val.id,
                                                                code: val.code,
                                                                name: val.name,
                                                                posologie: [],
                                                                coverage: val.isExclu,
                                                                unit: val.unit,
                                                                quantity: 1
                                                            }
                                                            return res;
                                                        }) : [];
                                                        localStorage.setItem("consultation", JSON.stringify(consultation));
                                                    }}
                                                />
                                            </div>
                                            {/*<div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Choisir le médicament</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-2' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className={"widget-media dz-scroll ps ps--active-y "+ (props.drugList.length > 0 ? "height300" : "height0")}
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.drugList.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={drugs.includes(disease)}
                                                                    key={j}
                                                                    onClick={e => addDrugs(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <span className="text-dark" >
                                                                            {disease.name}
                                                                        </span>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>*/}

                                            <div className='row'>
                                                <div className='col-6'>
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                                    >
                                                        {drugs.length === 0 && <span className='text-light'>Ajouter des médicaments...</span>}
                                                        {drugs.length > 0 && 
                                                            
                                                            <div className="basic-list-group">
                                                                <ListGroup>
                                                                    {drugs.map((drug, j) => {
                                                                        return <ListGroup.Item
                                                                            action
                                                                            active={drug.active}
                                                                            key={j}
                                                                            className="mb-1 flex-column align-items-start"
                                                                            style={{padding: "10px 20px", zIndex: "unset"}}
                                                                            onClick={(e) => focusOnDrug(drug.code, e)}
                                                                            >
                                                                            <h6 className={drug.active ? "text-white m-0" : "text-dark m-0"}>
                                                                                {drug.name}
                                                                                {drug.active ? 'active' : 'inactive'}
                                                                                <small className={'text-' + (drug.coverage ? 'danger' : 'success')}>{drug.coverage ? '(Exclu)' : '(Couvert)'}</small>
                                                                            </h6>
                                                                            <div className='input-group my-1 mx-0 input-secondary' style={{width:'25%'}}>
                                                                                <input
                                                                                    type='number'
                                                                                    value={drug.quantity}
                                                                                    className='form-control'
                                                                                    style={{height: '25px'}}
                                                                                    min={1}
                                                                                    onChange={(e) => {
                                                                                            setDrugs(drugs.map(drg => { 
                                                                                                if(drg.code === drug.code){
                                                                                                    drg.quantity = e.target.value
                                                                                                }
                                                                                                return drg;
                                                                                            }));
                                                                                            let consultation = JSON.parse(localStorage.getItem("consultation"));
                                                                                            consultation.medocs = drugs.map(drg => { 
                                                                                                if(drg.code === drug.code){
                                                                                                    drg.quantity = e.target.value
                                                                                                }
                                                                                                return drg;
                                                                                            });
                                                                                            localStorage.setItem("consultation", JSON.stringify(consultation));
                                                                                        }
                                                                                    }
                                                                                />
                                                                                <div className='input-group-append' style={{height: '25px'}}>
                                                                                    <span className='input-group-text'>{drug.unit}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row pl-3 d-flex flex-wrap justify-content-between '>
                                                                                {drug.posologie.map((pos, i) => {
                                                                                    return <Badge variant="dark light" key={i} style={{padding: "0 5px"}}>
                                                                                        <span>{composePosologie(pos)}</span>
                                                                                        <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                                            onClick={(e) => removePosologie(e, drug.code, i)}
                                                                                        >
                                                                                            <i className="fa fa-close"></i>
                                                                                        </button>
                                                                                    </Badge>
                                                                                })
                                                                                }
                                                                            </div>
                                                                            </ListGroup.Item>
                                                                        })
                                                                    }
                                                                </ListGroup>
                                                            </div>
                                                        }
                                                    </PerfectScrollbar>

                                                </div>
                                                <div className='col-6'>
                                                    <h5 className='mt-4 text-primary'>Posologie</h5>
                                                    <div className="border-top mt-2 mb-4"></div>

                                                    <div className='row mt-5 px-3'>
                                                        <Badge variant="dark light mr-1 mb-1">
                                                            <span className='text-danger'>{composePosologie({quantite,unit,frequence,periode, duration,durationUnit,repas: repas.value,voie: voie.value,})}</span>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                
                                                            >
                                                                <i className="fa fa-heart"></i>
                                                            </button>
                                                        </Badge>
                                                    </div>

                                                    <div className='row d-flex flex-wrap justify-content-between mt-1 mb-1'>
                                                        <div className='mb-1'>
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.quantity')}</strong>
                                                            </label>
                                                            <div className='input-group'>
                                                                <input
                                                                    value={quantite}
                                                                    type='number'
                                                                    min='0.1'
                                                                    width={"50px"}
                                                                    className='form-control input-group-prepend'
                                                                    onChange={e => setQuantite(e.target.value)}
                                                                />
                                                                
                                                                <Dropdown className='input-group-prepend'>
                                                                    <Dropdown.Toggle
                                                                        variant=''
                                                                        className='btn btn-primary p-1 dropdown-toggle'
                                                                        type='button'
                                                                        data-toggle='dropdown'
                                                                    >
                                                                        {unit}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='dropdown-menu'>
                                                                        { units.map(unit => { 
                                                                            return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                                            })
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                        <div className='mb-1'>
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.frequency')}</strong>
                                                            </label>
                                                            <div className='input-group'>
                                                                <input
                                                                    value={frequence}
                                                                    type='number'
                                                                    min='1'
                                                                    disabled={!periode.includes("heure") && !periode.includes("intervalle d'heure")}
                                                                    className='form-control input-default '
                                                                    onChange={e => setFrequence(e.target.value)}
                                                                />
                                                                
                                                                <DropdownMultiselect
                                                                    className='input-group-prepend'
                                                                    selected={periode}
                                                                    buttonClass='btn btn-primary px-2 rounded-0'
                                                                    options={frequency.map(freq => {return freq.value})}
                                                                    handleOnChange={managePeriod}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='mb-1'>
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.duration')}</strong>
                                                            </label>
                                                            <div className='input-group'>
                                                                <input
                                                                    value={duration}
                                                                    type='number'
                                                                    min='1'
                                                                    className='form-control input-default '
                                                                    onChange={e => setDuration(e.target.value)}
                                                                />
                                                                
                                                                <Dropdown className='input-group-prepend'>
                                                                    <Dropdown.Toggle
                                                                        variant=''
                                                                        className='btn btn-primary p-1 dropdown-toggle'
                                                                        type='button'
                                                                        data-toggle='dropdown'
                                                                    >
                                                                        {durationUnit}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='dropdown-menu'>
                                                                        { durationUnits.map(unit => { 
                                                                            return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setDurationUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                                            })
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                        <div className='mb-1' style={{width: "20%"}}>
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.meal')}</strong>
                                                            </label>
                                                            <Select
                                                                selected={repas}
                                                                onChange={setRepas}
                                                                options={meals}
                                                                style={{
                                                                lineHeight: '40px',
                                                                color: '#7e7e7e',
                                                                paddingLeft: ' 15px',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='mb-1'>
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.administration-route')}</strong>
                                                            </label>
                                                            <Select
                                                                selected={voie}
                                                                onChange={setVoie}
                                                                options={ways}
                                                                style={{
                                                                lineHeight: '40px',
                                                                color: '#7e7e7e',
                                                                paddingLeft: ' 15px',
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='mb-1 justify-self-end'>
                                                            <label className='mb-1 text-white'>
                                                                <strong>{t('consultation.add')}</strong>
                                                            </label>
                                                            <Button variant='primary' onClick={e => addPosologie(e)}>
                                                                <i className='fa fa-check' />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs
    };
};

export default connect(mapStateToProps)(PharmacyForm);
