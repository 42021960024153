import { connect, useDispatch } from 'react-redux';
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {
    loadingToggleAction, identificationAssuAction, identificationCodeAssuAction, reInitAuthAction, resendSMSTriggerAction
} from '../../../store/actions/PatientAction';
import { listParamIdentAction } from '../../../store/actions/ParamerterAction';
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';

const NumAssuIdentify = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {currentUser, savedNumAssu, authStepOne, authStepTwo, codeAttempts, identParams, history} = props;
    const [numAssu, setNumAssu] = useState('');
    const [code, setCode] = useState('');
    const [showResendSMS, setShowResendSMS] = useState(false);
    const [withOtp, setWithOtp] = useState(false);
    
    const authenticate = () => {
        if(!authStepOne && !authStepTwo) {
            let cardIdent = isCardIdentPermitted() && !withOtp;
            let data = {
                id: numAssu,
                providerId: currentUser.providerId,
                providerName: currentUser.provider.providerName,
            };
            dispatch(loadingToggleAction(true));
            dispatch(identificationAssuAction(data, cardIdent, history));
        }else{
            let data = {clientId: numAssu || savedNumAssu, code}; 
            dispatch(loadingToggleAction(true));
            dispatch(identificationCodeAssuAction(data, history));
        }
    }

    const resendSMS = () => {
        let data = numAssu || savedNumAssu;
        dispatch(loadingToggleAction(true));
        dispatch(resendSMSTriggerAction());
        dispatch(identificationAssuAction(data));
    }

    const isCardIdentPermitted = () => {
        //return identParams.find(elt => elt.identificationType === 'carte-sante' && elt.status)
        return true;
    }

    useEffect(() => {
        if(codeAttempts === 3) {
            dispatch(reInitAuthAction());
            setShowResendSMS(false);
        }
    }, [codeAttempts]);

    useEffect(() => {
        if(authStepOne) {
            setTimeout(() => {
                setShowResendSMS(true);
            }, 5000)
        }
    }, [authStepOne]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

      useEffect(() => {
        if(hasMenu('MENIDENT') && canDo('MENIDENT', 'ACIDENTNUMASSU')){
            dispatch(listParamIdentAction());
            dispatch(reInitAuthAction());
        }else{
            props.history.push("/provider-view/"+props.currentUser.providerId);
        }
    }, []);


    return (
        <>
            <div className="authincation h-100 p-meddle">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                    <div className="auth-form">
                                            <h4 className="text-center mb-4">
                                                {t('patient.authentication-patient')}
                                            </h4>
                                            <div className="justify-content-center rounded-circle">
                                                {!authStepOne && !authStepTwo && <>
                                                    <h5 className='mt-4 text-primary'>{t('patient.step1')}</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                                            <input
                                                            type='checkbox'
                                                            checked={withOtp}
                                                            onChange={(e) => setWithOtp(e.target.checked)}
                                                            disabled={!isCardIdentPermitted()}
                                                            className='custom-control-input'
                                                            id='customCheckBox5'
                                                            required
                                                            />
                                                            <label
                                                            className='custom-control-label'
                                                            htmlFor='customCheckBox5'
                                                            >
                                                            {t('common.with-otp')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className='col-12 form-group'>
                                                            <label className='mb-1 '>
                                                                <strong>{withOtp ? t('patient.assured-number') : t('common.with-otp-label')}</strong>
                                                            </label>
                                                            <input type='text' className='form-control'
                                                                value={numAssu}
                                                                onChange={(e) => setNumAssu(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                </>}
                                                {authStepOne && !authStepTwo && (codeAttempts < 3) && <>
                                                    <h5 className='mt-4 text-primary'>Etape 2</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className='col-12 form-group'>
                                                            <label className='mb-1 '>
                                                                <strong>{t('patient.enter-sms-code')}</strong><br/>
                                                                <small>Code SMS({3 - codeAttempts} tentatives restantes)</small>
                                                            </label>
                                                            <input type='text' className='form-control'
                                                                value={code}
                                                                onChange={(e) => setCode(e.target.value)}
                                                                />
                                                            {showResendSMS && <a href="#" 
                                                            style={{color: "#24439D", textDecoration: "underline"}}
                                                            onClick={resendSMS}>
                                                                {t('patient.resend-code')}
                                                            </a>}
                                                        </div>
                                                    </div>
                                                </>}

                                                
                                                { props.showLoading && 
                                                    <Button variant="outline-primary btn-lg" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />{' '}
                                                        <span className="visually-hidden">{t('patient.authentication-progress')}</span>
                                                    </Button> }
                                                { !props.showLoading &&
                                                    <Button type="submit" variant="outline-primary btn-lg"  onClick={authenticate}>Authentifier
                                                    </Button>
                                                }
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
const mapStateToProps = (state) => {
    return {
        savedNumAssu: state.clientAuth.numAssu,
        authStepOne: state.patient.authStepOne,
        authStepTwo: state.patient.authStepTwo,
        codeAttempts: state.patient.codeAttempts,
        identParams: state.parameters.identParams,
        showLoading: state.patient.showLoading,
        currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(NumAssuIdentify));