import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { startLoader, getFacturesImpayesAction, addFactureBordereauAction, removeBordereauAction } from "../../../store/actions/FacturationAction";
import PerfectScrollbar from "react-perfect-scrollbar";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import './BordereauForm.css';
import { removeFactureBordereau } from "../../../services/FacturationService";

export const BordereauUpdateModal = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { bordereau, closeModal, factureImpayees, type } = props;
    
    const [dateMin, setDateMin] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
    const [dateMax, setDateMax] = useState(new Date());
    const [choosenBills, setChoosenBills] = useState([]);

    const searchFactures = (min, max) => {
         //dispatch(startLoader());
         dispatch(getFacturesImpayesAction(props.currentUser.providerId, min, max));
    }

    

    useEffect(() => {
        //dispatch(startLoader());
        var date = new Date();
        let dateDebut = new Date(date.getFullYear(), date.getMonth(), 1);
        let dateFin = new Date();
        dispatch(getFacturesImpayesAction(props.currentUser.providerId, dateDebut, dateFin));
    }, []);


    const saveBordereauChanges = () => {
        let data = choosenBills.map(elt => elt.id);
        //dispatch(startLoader());
        if(type === 'ajout') {
            dispatch(addFactureBordereauAction(bordereau.id, data, props.currentUser.providerId));
        }else{
            dispatch(removeBordereauAction(bordereau.id, data, props.currentUser.providerId));
        }
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    return (
        <>
            <Modal.Header>
                <Modal.Title className="text-center" >
                        {type === 'ajout' ? "Ajout" : "Retrait"} de factures au bordereau {' '}<br/>
                        <b className="text-primary" >{bordereau.name}</b>
                </Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => closeModal()}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-end">
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>Date minimum</strong>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="dd/MM/yyyy"
                                    disableFuture
                                    value={dateMin}
                                    onChange={(val) => {
                                        setDateMin(val);
                                        searchFactures(val, dateMax);
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>Date maximum</strong>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="dd/MM/yyyy"
                                    disableFuture
                                    value={dateMax}
                                    onChange={(val) => {
                                        setDateMax(val);
                                        searchFactures(dateMin, val);
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>

                
                
                <div className='row '>
                    {type === 'retrait' && <div className='col-12'>
                        <h4>Factures ajoutées au bordereau</h4>
                        <PerfectScrollbar
                            id="DZ_W_Todo2"
                            className="widget-media dz-scroll ps ps--active-y height300 text-center"
                        >
                            <div className="factures">
                                {bordereau.factureDetails?.map(facture => {
                                    return <div className="row justify-content-between py-4 pl-2">
                                        <div className='col-12 custom-control custom-checkbox checkbox-danger'>
                                            <input
                                                type='checkbox'
                                                checked={choosenBills.find(elt => elt.id === facture.id)}
                                                onChange={(e) => 
                                                    e.target.checked ? 
                                                    setChoosenBills([...choosenBills, facture]) :
                                                    setChoosenBills(choosenBills.filter(bill => bill.id !== facture.id))
                                                }
                                                className='custom-control-input'
                                                id={'customCheckBox'+facture.id}
                                                required
                                            />
                                            <label
                                                className='custom-control-label'
                                                htmlFor={'customCheckBox'+facture.id}
                                            >
                                                {facture.name} - {facture.patientName} <span class="text-bold">total: {facture.montantTotal}</span>
                                            </label>
                                        </div>
                                        {choosenBills.find(elt => elt.id === facture.id) && <>
                                            {facture.facturePrestataireDetails.map(detail => {
                                                return <div className="col-4 border border-primary facture-details">
                                                    <h4>{detail.libelle} - {formatDate(new Date(detail.dateExecution))}</h4>
                                                    <div className="actes">
                                                        <h5 class="text-primary">{detail.priceToPayByActiva}</h5>
                                                    </div>
                                                </div>
                                            })}
                                        </>}
                                    </div>
                                })}
                            </div>
                        </PerfectScrollbar>
                    </div>}
                    {type === 'ajout' && <div className='col-12'>
                        <h4>{t('common.pending-bills')}</h4>
                        <PerfectScrollbar
                            id="DZ_W_Todo2"
                            className="widget-media dz-scroll ps ps--active-y height300 text-center"
                        >
                            <div className="factures">
                                {factureImpayees.map(facture => {
                                    return <div className="row justify-content-between py-4 pl-2">
                                        <div className='col-12 custom-control custom-checkbox checkbox-danger'>
                                            <input
                                                type='checkbox'
                                                checked={choosenBills.find(elt => elt.id === facture.id)}
                                                onChange={(e) => 
                                                    e.target.checked ? 
                                                    setChoosenBills([...choosenBills, facture]) :
                                                    setChoosenBills(choosenBills.filter(bill => bill.id !== facture.id))
                                                }
                                                className='custom-control-input'
                                                id={'customCheckBox'+facture.id}
                                                required
                                            />
                                            <label
                                                className='custom-control-label'
                                                htmlFor={'customCheckBox'+facture.id}
                                            >
                                                {facture.name} - {facture.patientName} <span class="text-bold">total: {facture.montantTotal}</span>
                                            </label>
                                        </div>
                                        {choosenBills.find(elt => elt.id === facture.id) && <>
                                            {facture.facturePrestataireDetails.map(detail => {
                                                return <div className="col-4 border border-primary facture-details">
                                                    <h4>{detail.libelle} - {formatDate(new Date(detail.dateExecution))}</h4>
                                                    <div className="actes">
                                                        <h5 class="text-primary">{detail.priceToPayByActiva}</h5>
                                                    </div>
                                                </div>
                                            })}
                                        </>}
                                    </div>
                                })}
                            </div>
                        </PerfectScrollbar>
                    </div> }   
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger light"
                    onClick={(e) => closeModal(e)}
                >
                    Annuler
                </Button>
                <Button
                    variant=""
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => saveBordereauChanges(e)}
                >
                    Soumettre
                </Button>
            </Modal.Footer>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        factureImpayees: state.facturation.factureImpayees,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(BordereauUpdateModal);