import React, { useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { updateParametersAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const HealthParamsModal = (props) => {
    const dispatch = useDispatch();
    const { consultation, manageHealthParamsModal, history } = props;
    const { t } = useTranslation();
    const [height, setHeight] = useState(consultation.dossierMedical?.healhtParameters[0]?.height || 0);
    const [weight, setWeight] = useState(consultation.dossierMedical?.healhtParameters[0]?.weight || 0);
    const [imc, setImc] = useState(consultation.dossierMedical?.healhtParameters[0]?.imc || 0);
    const [temperature, setTemperature] = useState(consultation.dossierMedical?.healhtParameters[0]?.temperature || 0);
    const [bloodPressionS, setBloodPressionS] = useState(consultation.dossierMedical?.healhtParameters[0]?.bloodPressionS || 0);
    const [bloodPressionD, setBloodPressionD] = useState(consultation.dossierMedical?.healhtParameters[0]?.bloodPressionD || 0);
    const [frequenceCard, setFreqCard] = useState(consultation.dossierMedical?.healhtParameters[0]?.frequenceCard || 0);
    const [frequenceResp, setFreqResp] = useState(consultation.dossierMedical?.healhtParameters[0]?.frequenceResp || 0);

    const saveParamsChanges = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        let data = { 
            doctorId: consultation.doctorId,
            patientId: consultation.beneficiaryId,
            doctorName: consultation.doctorName,
            patientName: consultation.beneficiaryName,
            providerId: props.currentUser.providerId,
            motif: consultation.motif,
            date: new Date(),
            healhtParameter: {
                height,
                weight,
                imc,
                temperature,
                bloodPressionS,
                bloodPressionD,
                executedBy: props.currentUser.id,
                frequenceCard,
                frequenceResp
            }
        }

        dispatch(updateParametersAction(consultation.id, data));
        manageHealthParamsModal();
     }

    return (
            <>
                    <Modal.Header>
                        <Modal.Title className="text-center" >
                               {t('client.healthParameters-modification')} {' '}<br/>
                                <b className="text-primary" >{consultation.beneficiaryName}</b>
                        </Modal.Title>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => manageHealthParamsModal()}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                    <form
                                        onSubmit={(e) => saveParamsChanges(e)}
                                    >

                                            <section>
                                                <h5 className='mt-4 text-primary'>{t('client.healthParameters')}</h5>
                                                <div className="border-top mt-2 mb-4"></div>
                                                <div className="row">
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('client.height')}(cm)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={height}
                                                                onChange={(e) => {
                                                                    setHeight(e.target.value)
                                                                    setImc(weight / ((e.target.value / 100) ** 2))
                                                                }}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('client.weight')}(kg)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={weight}
                                                                onChange={(e) => {
                                                                    setWeight(e.target.value)
                                                                    setImc(e.target.value / ((height/100) ** 2))
                                                                }}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('common.imc')}(kg/m)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={imc}
                                                                onChange={(e) => setImc(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('common.temperature')}(°C)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={temperature}
                                                                onChange={(e) => setTemperature(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('client.systolicPressure')}(mmHg)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={bloodPressionS}
                                                                onChange={(e) => setBloodPressionS(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('client.diastolicPressure')}(mmHg)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={bloodPressionD}
                                                                onChange={(e) => setBloodPressionD(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.heart-rate')}(bpm)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={frequenceCard}
                                                                onChange={(e) => setFreqCard(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-2">
                                                        <div className="form-group">
                                                            <label className='mb-1 '>
                                                                <strong>{t('consultation.respiratory-rate')}(cycle/min)</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={frequenceResp}
                                                                onChange={(e) => setFreqResp(e.target.value)}
                                                                />
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </section>
                                    </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={(e) => manageHealthParamsModal(e)}
                        >
                           {t('common.cancel')}
                        </Button>
                        <Button
                            variant=""
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => saveParamsChanges(e)}
                        >
                            {t('client.submit')}
                        </Button>
                    </Modal.Footer>
            </>
    )
};

export default HealthParamsModal;