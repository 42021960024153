import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TextInput from 'react-autocomplete-input';
import { searchDiseasesAction } from '../../../../../store/actions/ConsultationActions';
import 'react-autocomplete-input/dist/bundle.css';
import './ConsultationStyle.css';
import { Badge, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
const DiagnosisForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consult = JSON.parse(localStorage.getItem("consultation"));


    const [final, setFinal] = useState((consult && consult.diagnostics) ? consult.diagnostics.final : false);
    const [diagnostic, setDiagnostic] = useState((consult && consult.diagnostics && consult.diagnostics.diagnostic) ? consult.diagnostics.diagnostic : []);
    const [keyword, setKeyword] = useState("");

    const setDiagnosisType = (val) => {
        setFinal(val);
        saveDiagnosticTypeUpdates(val);
    }

    const addDisease = (e, disease) => {
        e.preventDefault();
        if(diagnostic.filter(diag => diag.code === disease.code).length === 0) {
            setDiagnostic([...diagnostic, disease]);
            saveDiagnosticUpdates([...diagnostic, disease]);
        }
    }

    const removeElt = (val, e) => {
        if(e.type === "click") {
            setDiagnostic(diagnostic.filter(diag => {return diag.code !== val}));
            saveDiagnosticUpdates(diagnostic.filter(diag => {return diag.code !== val}));
        }
    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchDiseasesAction(keyword));
        }else{
            dispatch(searchDiseasesAction('@@@'));
        }
    }, [keyword]);

    const saveDiagnosticUpdates = (data) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        if(!consultation.diagnostics){
            consultation.diagnostics = {
                final: false
            };
        }
        consultation.diagnostics.diagnostic = data;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    const saveDiagnosticTypeUpdates = (val) => {
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        if(!consultation.diagnostics){
            consultation.diagnostics = {};
        }
        consultation.diagnostics.final = val;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }

    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Diagnostic</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='d-flex justify-content-start'>

                                        <div className='custom-control custom-checkbox mb-3 checkbox-danger'>
                                            <input
                                            type='checkbox'
                                            checked={final}
                                            onChange={(e) => setDiagnosisType(e.target.checked)}
                                            className='custom-control-input'
                                            id='customCheckBox5'
                                            required
                                            />
                                            <label
                                            className='custom-control-label'
                                            htmlFor='customCheckBox5'
                                            >
                                            {t('consultation.final-diagnosis')}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('consultation.final-diagnosis')}</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={props.diseases.map(elt => {return {label: elt.name, value: elt.code}})}
                                                    value={diagnostic.map(elt => {return {label: elt.name, value: elt.code}})}
                                                    placeholder={t('consultation.enter-keyword-affliction')}
                                                    search
                                                    isMulti
                                                    onInputChange={(val) => {
                                                        if(val.length >= 1){
                                                            dispatch(searchDiseasesAction(val));
                                                        }
                                                    }}
                                                    onChange={(vals) => {
                                                        setDiagnostic(vals ? vals.map(val => {
                                                            return {code: val.value, name: val.label}
                                                        }) : []);
                                                        saveDiagnosticUpdates(vals ? vals.map(val => {
                                                            return {code: val.value, name: val.label}
                                                        }) : []);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className='row'>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Recherche l'affection</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-4' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height300"
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.diseases.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={diagnostic.includes(disease)}
                                                                    key={j}
                                                                    onClick={e => addDisease(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <h6 className={diagnostic.includes(disease) ? "text-white" : "text-dark"} >
                                                                            {disease.name}
                                                                        </h6>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>

                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <h5 className='mt-4 text-primary'>Diagnostic</h5>
                                            <div className="border-top mt-2 mb-4"></div>
                                            {diagnostic?.map((diag, i) => {
                                                return (
                                                    <Badge variant="dark light mr-1 mb-1" key={i}>
                                                        <span>[{diag.code}] {' - '} {diag.name}</span>
                                                        <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                            onClick={(e) => removeElt(diag.code, e)}
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </button>
                                                    </Badge>
                                                )
                                            })}
                                        </div>
                                    </div>*/}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => {
    return {
        diseases: state.consultation.diseases
    };
};

export default connect(mapStateToProps)(DiagnosisForm);
