import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import {
    listClientsAction,
    startLoader,
    checkClientRegistrationFromClientAction
} from '../../../store/actions/ClientAuthActions';
import { getBeneficiairesAction, loadingToggleAction,loadingToggleActionStop } from "../../../store/actions/ContratActions";
import { Button, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import {format} from 'date-fns';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { useTranslation } from "react-i18next";

export const ClientSearch = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let data = useRef([]);
    const [searchNumPoliceValue, setSearchNumPoliceValue] = useState("");
    const [searchNomSouscripValue, setSearchNomSouscripValue] = useState("");
    const [searchNomBenefValue, setSearchNomBenefValue] = useState("");

   /*useEffect(() => {
        if(searchValue.trim().length >= 3){
            dispatch(loadingToggleAction());
            dispatch(getBeneficiairesAction(searchValue));
            //dispatch(listClientsAction(searchValue));
        }else{
            dispatch(getBeneficiairesAction('@@@'));
        }
    }, [searchValue]);*/
    
    useEffect(() => {
        if(props.showContratLoading){
            dispatch(loadingToggleActionStop());
        }
    }, []);
    const searchAssure = (e) => {
        e.preventDefault();
        dispatch(loadingToggleAction());
        dispatch(getBeneficiairesAction(
            searchNomBenefValue.trim().length > 0 ? searchNomBenefValue : null, 
            searchNumPoliceValue.trim().length > 0 ? searchNumPoliceValue : null, 
            searchNomSouscripValue.trim().length > 0 ? searchNomSouscripValue : null));
        //dispatch(listClientsAction(searchValue));
    }
 
    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };

    const registerClient = (client) => {
        dispatch(startLoader());
        dispatch(checkClientRegistrationFromClientAction(client.id, props.history));
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

      useEffect(() => {
        if(hasMenu('MENENROLL') && canDo('MENENROLL', 'ACENRRF')){
        }else{
            props.history.push("/provider-view/"+props.currentUser.providerId);
        }
    }, []);

    return (
        <>
            { props.showContratLoading  && <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }
            { !props.showContratLoading &&
            <>
            <p className="text-center">{t('patient.search-assured-enrol')}</p>
            <form onSubmit={searchAssure}>
                <div className="row justify-content-center">
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('patient.police-number')}
                                value={searchNumPoliceValue}
                                onChange={(e) => setSearchNumPoliceValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('patient.subscriber-name')}
                                value={searchNomSouscripValue}
                                onChange={(e) => setSearchNomSouscripValue(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('patient.beneficiary-name')}
                                value={searchNomBenefValue}
                                onChange={(e) => setSearchNomBenefValue(e.target.value)}
                            />
                        </div>
                    </div>
                    { props.showLoading && 
                        <div className='col-6'>
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                <span className="visually-hidden">{t('common.await-text')}</span>
                            </Button> 
                        </div>}
                    { !props.showLoading &&
                        <div className='col-1'>
                            <button type='submit' className='btn btn-primary btn-block'>
                                <i className='la la-search'></i>
                            </button>
                        </div> }
                </div>
            </form>

            { !props.showLoading && props.clients.length === 0 && 
                <p className="text-center my-5"> {t('patient.no-result')}</p>
            }

            { !props.showLoading && props.clients.length > 0 && <div className="row">
                <div className="col-xl-12">
                    <div className="table-responsive">
                        <div id="patient_list" className="dataTables_wrapper no-footer">

                            <table
                                id="example5"
                                className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                                role="grid"
                                aria-describedby="example5_info">

                                <thead>
                                    <tr role="row">
                                        <th
                                            className="patient_strg"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            onClick={() => chackboxFun("all")}
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-sort="ascending"
                                            style={{ width: 24 }}
                                        >
                                            <div className="checkbox text-right align-self-center">
                                                <div className="custom-control custom-checkbox ">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="checkAll"
                                                    required
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkAll"
                                                />
                                                </div>
                                            </div>
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            {t('patient.contract-num')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            {t('patient.insured-number')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            {t('common.name-firstname')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date Check In: activate to sort column ascending"
                                            style={{ width: 113 }}
                                        >
                                            {t('common.city')}
                                        </th>
                                        {/*<th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                            style={{ width: 108 }}
                                        >
                                            Téléphone
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Doctor Assgined: activate to sort column ascending"
                                            style={{ width: 135 }}
                                        >
                                            Email
                                        </th>*/}
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                            style={{ width: 68 }}
                                        >
                                            {t('common.gender')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Status: activate to sort column ascending"
                                            style={{ width: 103 }}
                                        >
                                            {t('common.birthdate')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label=": activate to sort column ascending"
                                            style={{ width: 24 }}
                                        />
                                    </tr>
                                </thead>

                                <tbody>
                                    {props.clients.map((client) => {
                                        return <tr role="row" className="odd">
                                                    <td className="patient_checkbox">
                                                        <div className="checkbox text-right align-self-center">
                                                            <div className="custom-control custom-checkbox ">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="customCheckBox1"
                                                                required
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor="customCheckBox1"
                                                            />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{client.numeroPolice}</td>
                                                    <td>{client.id}</td>
                                                    <td>{client.nom}</td>
                                                    <td>{client.ville}</td>
                                                    {/*<td>{client.pays}</td>
                                                    <td>{client.email}</td>*/}
                                                    <td>{client.sexe}</td>
                                                    <td>{format(new Date(client.dateNaissance), 'dd/MM/yyyy')}</td>
                                                    <td className="d-flex">
                                                        <button className="btn btn-primary shadow btn-xs sharp mr-2"
                                                                onClick={(e) => registerClient(client)}
                                                            >
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>}
            </>
            }
        </> 
    )
};

const mapStateToProps = (state) => {
    return {
        clients: state.contrat.beneficiaires,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.clientAuth.showLoading,
        showContratLoading: state.contrat.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ClientSearch));