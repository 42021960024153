import { getBonByCode, delieverMedicaments, requestSubstituteMedicament, substituteMedicament, rejectSubstituteMedicament, getSubsRequests, getSubsRequestsHistory } from '../../services/PharmacieService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BON_CONFIRMED_ACTION = '[getPharmBon action] confirmed getPharmBon';
export const GET_BON_FAILED_ACTION = '[getPharmBon action] failed getPharmBon';

export const GET_SUBS_REQUEST_CONFIRMED_ACTION = '[getSubsRequest action] confirmed getSubsRequest';
export const GET_SUBS_REQUEST_FAILED_ACTION = '[getSubsRequest action] failed getSubsRequest';

export const DELIEVER_CONFIRMED_ACTION = '[deleiever action] confirmed deleiever';
export const DELIEVER_FAILED_ACTION = '[deleiever action] failed deleiever';

export const REQUEST_SUBSTITUTE_CONFIRMED_ACTION = '[requestSubstitute action] confirmed requestSubstitute';
export const REQUEST_SUBSTITUTE_FAILED_ACTION = '[requestSubstitute action] failed requestSubstitute';

export const SUBSTITUTE_CONFIRMED_ACTION = '[substitute action] confirmed substitute';
export const SUBSTITUTE_FAILED_ACTION = '[substitute action] failed substitute';

export const GET_SUBS_REQUEST_HIST_CONFIRMED_ACTION = '[getSubsRequestHist action] confirmed getSubsRequestHist';
export const GET_SUBS_REQUEST_HIST_FAILED_ACTION = '[getSubsRequestHist action] failed getSubsRequestHist';

export const START_LOADER_ACTION = '[pharmacieLoader action] started pharmacieLoader';

var I18n = require('react-redux-i18n').I18n;

export const getBonByCodeAction = (code) => {
    
    return (dispatch) => {
        getBonByCode(code)
        .then((response) => {
            
            dispatch(confirmedGetBonAction(response.data));
            //dispatch(getSubstitutionRequestsAction())
        })
        .catch((error) => {
            errorToastr(I18n.t('pharmacie.get_bon_error')+error.response?.data?.message);
            dispatch(getBonFailedAction(error.response?.data?.message));
        });
    };
};

export const getSubstitutionRequestsAction = () => {
    
    return (dispatch) => {
        getSubsRequests()
        .then((response) => {
            
            dispatch(confirmedSubsRequestAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('pharmacie.get_subs_error')+error.response?.data?.message);
            dispatch(getSubsRequestFailedAction(error.response?.data?.message));
        });
    };
};

export const delieverDrugsAction = (data) => {
    
    return (dispatch) => {
        delieverMedicaments(data)
        .then((response) => {
            
            successToastr(I18n.t('pharmacie.livraison_success'));
            dispatch(confirmedDelievryBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('pharmacie.livraison_error')+error.response?.data?.message);
            dispatch(delievryFailedAction(error.response?.data?.message));
        });
    };
};

export const askSubstitutionAction = (data) => {
    
    return (dispatch) => {
        requestSubstituteMedicament(data)
        .then((response) => {
            
            successToastr(I18n.t('pharmacie.subs_req_success'));
            dispatch(confirmedRequestSubstiteAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('pharmacie.subs_req_error')+error.response?.data?.message);
            dispatch(requestSubstiteFailedAction(error.response?.data?.message));
        });
    };
};

export const substituteDrugAction = (status, demandeId) => {
    
    return (dispatch) => {
        if(status) {
            substituteMedicament(demandeId)
            .then((response) => {
                
                successToastr(I18n.t('pharmacie.subs_validated_success'));
                dispatch(confirmedSubstiteAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('pharmacie.subs_validated_error')+error.response?.data?.message);
                dispatch(substiteFailedAction(error.response?.data?.message));
            });
        }else{
            rejectSubstituteMedicament(demandeId)
            .then((response) => {
                
                successToastr(I18n.t('pharmacie.subs_rejected_success'));
                dispatch(confirmedSubstiteAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('pharmacie.subs_rejected_success')+error.response?.data?.message);
                dispatch(substiteFailedAction(error.response?.data?.message));
            });
        }
    };
};

export const getSubstitutionRequestsHistoryAction = (providerId) => {
    
    return (dispatch) => {
        getSubsRequestsHistory(providerId)
        .then((response) => {
            
            dispatch(confirmedSubsRequestHistAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('pharmacie.get_subs_error')+error.response?.data?.message);
            dispatch(getSubsRequestHistFailedAction(error.response?.data?.message));
        });
    };
};



export function getSubsRequestHistFailedAction(payload) {
    return {
        type: GET_SUBS_REQUEST_HIST_FAILED_ACTION,
        payload,
    };
};

export function confirmedSubsRequestHistAction(payload) {
    return {
        type: GET_SUBS_REQUEST_HIST_CONFIRMED_ACTION,
        payload,
    };
};


export function confirmedGetBonAction(payload) {
    return {
        type: GET_BON_CONFIRMED_ACTION,
        payload,
    };
};

export function getBonFailedAction(payload) {
    return {
        type: GET_BON_FAILED_ACTION,
        payload,
    };
};

export function getSubsRequestFailedAction(payload) {
    return {
        type: GET_SUBS_REQUEST_FAILED_ACTION,
        payload,
    };
};

export function confirmedSubsRequestAction(payload) {
    return {
        type: GET_SUBS_REQUEST_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedDelievryBonAction(payload) {
    return {
        type: DELIEVER_CONFIRMED_ACTION,
        payload,
    };
};

export function requestSubstiteFailedAction(payload) {
    return {
        type: REQUEST_SUBSTITUTE_FAILED_ACTION,
        payload,
    };
};

export function confirmedRequestSubstiteAction(payload) {
    return {
        type: REQUEST_SUBSTITUTE_CONFIRMED_ACTION,
        payload,
    };
};

export function substiteFailedAction(payload) {
    return {
        type: SUBSTITUTE_FAILED_ACTION,
        payload,
    };
};

export function confirmedSubstiteAction(payload) {
    return {
        type: SUBSTITUTE_CONFIRMED_ACTION,
        payload,
    };
};

export function delievryFailedAction(payload) {
    return {
        type: DELIEVER_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};